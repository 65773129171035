import {now} from "moment/moment";
import { toast } from 'react-toastify';
import {addHours, differenceInDays, format} from 'date-fns';
import {BASE_PRICE_RANGES} from "config";

export const capitalizeFirstLetter=(string)=>{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const numberWithSpace=(num)=> {
    if(num){
        const parts = num.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g," ");
        return parts.join(",");
    }
    return 0;
}

export const campaignLengthInDays =(timeFrom,timeTo)=>{
    let _temp_time_from = timeFrom;
    let _temp_time_to = timeTo;
    // poprawka uwzględniająca przesunięcie czasu w październiku +1h
    if(timeFrom?.length < 11 && timeTo?.length < 11){
        _temp_time_from = `${timeFrom}T00:00:00`;
        _temp_time_to = `${timeTo}T00:00:00`;
    }
    const totalDays  = differenceInDays(new Date(_temp_time_from).getTime(),new Date(_temp_time_to).getTime());
    // const difference = new Date(timeTo).getTime() - new Date(timeFrom).getTime();
    // const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if(totalDays !== 0){
        return Math.abs(totalDays)+1;
    }
    return 1;
}

export const campaignElapsedDays=(timeFrom)=>{
    const difference = new Date(now()).getTime() - new Date(timeFrom).getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if(totalDays<0){
        return 0;
    }
    return  totalDays;
}

export const progressPercentage=(timeFrom, timeTo)=>{
    let val = 0;
    if(new Date(timeTo).getTime() > new Date(now()).getTime()){
        const campaignTimeInDays = campaignLengthInDays(timeFrom, timeTo);
        const leftTimeInDays = campaignLengthInDays(new Date(now()), timeTo);
        val = (100-((leftTimeInDays*100)/campaignTimeInDays));
    }else{
        return 100;
    }
    if(val<0){val=0}
    if(val>100){val=100}
    return val;
}

export const returnPercentage=(stat,est)=>{
    if(stat>0 && est>0){
        const val = ((stat*100)/(est) )?.toFixed(0);
        return Number(val);
    }
    return 0
}

// $probability = ((($this->progress->getAudience() / $this->daysElapsed) * ($this->days - $this->daysElapsed) + $this->progress->getAudience()) / $this->estimation->getAudience()) * 100;
// $cityAudience = $this->progress->getCityAudience($cityId);
// $probability = ( ( ($cityAudience / $this->daysElapsed) * ($this->days - $this->daysElapsed) + $cityAudience) / $this->estimation->getCityAudience($cityId)) * 100;
// elapsed -> ile dni upłynęło od startu
export const calcProbability=(campaignDays=0, daysElapsed=0,estimatedAudience=0,progressAudience=0)=>{
    let probability = 100;
    if(daysElapsed>0 && estimatedAudience>0 && progressAudience>0){
        // probability = ( ( (progressAudience/daysElapsed) * (campaignDays - daysElapsed) / estimatedAudience ) *100 );
        probability = ( ( ( (progressAudience/daysElapsed) * (campaignDays - daysElapsed) + progressAudience ) / estimatedAudience ) *100 );
    }else if(estimatedAudience===0){
        probability=0;
    }

    return Math.min(probability,100).toFixed(0);
}

/** {type, key, value} */
export const sessionStorageHelper = async ({type,key,value})=>{
    switch (type){
        case 'GET':{
            return new Promise((resolve)=>{
                const data = JSON.parse(sessionStorage.getItem(key));
                resolve(data);
            });
        }
        case 'SET':{
            return new Promise((resolve)=>{
                sessionStorage.setItem(key,JSON.stringify({data:value}));
                resolve("OK");
            });
        }
        case 'CLEAR_ITEM':{
            return new Promise((resolve)=>{
                sessionStorage.removeItem(key);
                resolve("OK");
            });
        }
        case 'CLEAR_ALL':{
            return new Promise((resolve)=>{
                sessionStorage.clear();
                resolve("OK");
            });
        }
        default: return null;
    }

}

/** variant= |info|success|warning|error| */
export const cToast=(props)=>{
    const{
        toastProps={
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        },
        msg="Empty",
        variant=null,
        toastId=false,
    }=props;
    if(variant){
        toast[variant](msg,{...toastProps,toastId});
    }else{
        toast(msg,{...toastProps, toastId});
    }
    // eslint-disable-next-line no-void
    return void 0;
}

export const gemiusDateFormatter=(date)=>{
    if(date){
        return addHours((
            new Date(date.slice(0, 4), date.slice(4, 6) - 1, date.slice(6, 8),
                date.slice(8, 10), date.slice(10, 12), date.slice(12, 14))
        ),1).toISOString();
    }
    return null;
}


/** MOCK resolve Promise [isSuccess, timeout, data] */
export const mockResolve = (isSuccess=true, timeout=1000, data={}) =>{
    return new Promise((resolve, reject)=>{
        setTimeout(()=>{
            if(isSuccess){
                resolve(data);
            }else {
                reject(new Error("mock-err"));
            }
        },timeout);
    });
}

/** return base price */
export const basePriceHelper=(totalScreensCount=0,campaignDuration=0, totalEmissions=0)=>{
    const screenDaysValue = totalScreensCount * campaignDuration;
    if(screenDaysValue >0){
        for(let i=0; i<=BASE_PRICE_RANGES.length; i++){
            if(screenDaysValue >= BASE_PRICE_RANGES[i].min && screenDaysValue <= BASE_PRICE_RANGES[i].max){
                return Number((BASE_PRICE_RANGES[i].priceFactor * totalEmissions).toFixed(0));
            }
        }
    }
    return 0;
}

/** new calcu calculateBaseBudget */
export const calculateBaseBudget=(_screenDays=0, _totalEmissions=0)=>{
    let __base_budget = 0;
    if(_screenDays>0 && _totalEmissions>0){
        const x = BASE_PRICE_RANGES.find(i=> (_screenDays >= i.min && _screenDays <= i.max));
        if(x){
            __base_budget =  Number((x.priceFactor * _totalEmissions).toFixed(0));
        }
    }
    return __base_budget;
}

/** return discount by price */
export const discountHelper=(budget=0, base_budget=0)=>{
    const dif = budget - base_budget;
    if(dif > 0 && budget > 0){
        return ((dif*100)/budget).toFixed(2);
    }
    return 0
}

/** return queryParams without null's */
export const queryParamsSiteLinkHelper=(query)=>{
    if(Object.keys(query)?.length>0){

        const nQuery = {...query,
            [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
            query.fieldFilter && Object.values(query.fieldFilter)?.[0]
        }

        return  Object.fromEntries(Object.entries(nQuery).filter(([_, v]) => {
            if(['fieldFilter','total','pages'].some(i => i === _)){return false}
            if(_ === "filter" && v ===""){return false}
            return v !== null;
        }));
    }
    return query;
}

/** Round to value helper */
export const roundOffTo= (num=0, factor=1)=>{
    return Math.ceil((num/factor)) * factor;
}