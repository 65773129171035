import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState = {
    locations:{
        isPending: false,
        error: null,
        items:[],
        arabox_uuids:[],
    },
    trafficData:{
        isPending: false,
        error: null,
        items: [],
    }
}

const slice = createSlice({
    name: 'traffic',
    initialState,
    reducers:{
        fetchLocations:(state,{payload})=>{
            state.locations.isPending = true;
        },
        fetchLocationsSuccess:(state,{payload})=>{
            // state.locations.arabox_uuids = new Set(payload.map(item=>item.arabox_uuid && item.arabox_uuid))
            state.locations.arabox_uuids = payload.reduce((acc, item)=>{
                if(item.arabox_uuid !== null && !acc.includes(item.arabox_uuid)){
                    acc.push(item.arabox_uuid);
                }
                return acc;
            },[]);
            state.locations.items = payload;
            state.locations.isPending = false;
        },
        fetchLocationsFailure:(state,{payload})=>{
            state.locations.error = payload;
            state.locations.isPending = false;
        },

        fetchTrafficData:(state,{payload})=>{
            state.trafficData.isPending = true;
        },
        fetchTrafficDataSuccess:(state,{payload})=>{
            state.trafficData.items = payload;
            state.trafficData.isPending = false;
        },
        fetchTrafficDataFailure:(state,{payload})=>{
            state.trafficData.error = payload;
            state.trafficData.isPending = false;
        }
    }
});

export const trafficActions = {
    fetchLocations: createAction("traffic/fetchLocations"),
    fetchLocationsSuccess: createAction("traffic/fetchLocationsSuccess"),
    fetchLocationsFailure: createAction("traffic/fetchLocationsFailure"),
    fetchTrafficData: createAction("traffic/fetchTrafficData"),
    fetchTrafficDataSuccess: createAction("traffic/fetchTrafficDataSuccess"),
    fetchTrafficDataFailure: createAction("traffic/fetchTrafficDataFailure"),
}

export default slice.reducer;