import {arahubAPI} from "config/axios";
import {API_GEMIUS_URL} from "config";
import {mockResolve} from "helpers";

import gemius_mock from "redux/mock_response/gemius_campaigns.json";


const getGemiusCampaigns= async (query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_GEMIUS_URL}campaign`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
    // return mockResolve(true,500,gemius_mock); /** TEST MOCK */

}

const gemiusService={
    getGemiusCampaigns,
}

export default gemiusService;