import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import plGlobal from "config/language/pl/pl_global.json";
import enGlobal from "config/language/en/en_global.json";

i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: 'pl',
    resources:{
        en: {
            "translation": enGlobal,
        },
        pl: {
            "translation": plGlobal,
        },
    },
    // react:{useSuspense: false}
});
export default i18next;