import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState ={
    ws:{
        isPending: false,
        status: null,
        channel: null,
    },
    path:{

    },
    realtime:{

    },
}

const slice= createSlice({
    name: '_ws',
    initialState,
    reducers:{
        turnOnWs: (state,{payload})=>{
            state.ws = initialState.ws;
            console.log('%c ---------------- turn-on-ws ------------------','color:lightblue');
        },
        turnOnWsSuccess: (state,{payload})=>{
            state.ws.isPending = false;
            state.ws.status = 'CONNECTED';
            console.log('✅ %cWebSocket is connected','color: green');
        },
        turnOnWsFailure: (state,{payload})=>{
            state.ws.isPending = false;
            state.ws.status = 'ERROR';
            console.log('✅ %cWebSocket error','color: red');
        },

        turnOffWs: (state,{payload})=>{
            state.ws.isPending = false;
            state.ws.status = 'DISCONNECTED';
            console.log('%c ------------------- turn-off-ws -------------------','color:lightblue');
        },

        putRealtimeData:(state,{payload})=>{
            console.log("putRealtimeData",payload);
        },
        putPathData:(state,{payload})=>{
            console.log("putPath",payload);
        },
    }
});

export const wsActions={
    turnOnWs: createAction('_ws/turnOnWs'),
    turnOnWsSuccess: createAction('_ws/turnOnWsSuccess'),
    turnOnWsFailure: createAction('_ws/turnOnWsFailure'),

    turnOffWs: createAction('_ws/turnOffWs'),
    putRealtimeData: createAction('_ws/putRealtimeData'),
    putPathData: createAction('_ws/putPathData'),
}

export default slice.reducer;