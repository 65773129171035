import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState = {
    industries:{
        isPending: false,
        error: null,
        industryItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: 'created_at',
            order:'desc'
        },
    },
    uploadFile:{
        isPending: false,
        error:null,
    },
    industryItem:{
        isPending: false,
        error: null,
        item:{},
    }
}

const slice = createSlice({
    name: 'industry',
    initialState,
    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
            }=payload;
            state.industries.query.size = pageSize;
            state.industries.query.page = page;
        },


        fetchIndustries:(state,{payload})=>{
            state.industries.error = null;
            state.industries.industryItems = [];
            state.industries.isPending = true;
        },
        fetchIndustriesSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.industries.query.total = total;
            state.industries.query.page = page;
            state.industries.query.pages = pages;
            state.industries.query.size = size;

            state.industries.error = null;
            state.industries.industryItems = items;
            state.industries.isPending = false;
        },
        fetchIndustriesFailure:(state,{payload})=>{
            state.industries.error = payload;
            state.industries.industryItems = [];
            state.industries.isPending = false;
        },


        fetchIndustry:(state,{payload})=>{
            state.industryItem.error = null;
            state.industryItem.item = {};
            state.industryItem.isPending = true;
        },
        fetchIndustrySuccess:(state,{payload})=>{
            state.industryItem.error = null;
            state.industryItem.item = payload;
            state.industryItem.isPending = false;
        },
        fetchIndustryFailure:(state,{payload})=>{
            state.industryItem.error = payload;
            state.industryItem.item = {};
            state.industryItem.isPending = false;
        },


        createIndustry:(state,{payload})=>{
            //
        },
        createIndustrySuccess:(state,{payload})=>{
            //
        },
        createIndustryFailure:(state,{payload})=>{
            state.industryItem.error = payload;
        },


        updateIndustry:(state,{payload})=>{
            //
        },
        updateIndustrySuccess:(state,{payload})=>{
            //
        },
        updateIndustryFailure:(state,{payload})=>{
            state.industryItem.error = payload;
        },


        deleteIndustry:(state,{payload})=>{
            //
        },
        deleteIndustrySuccess:(state,{payload})=>{
            //
        },
        deleteIndustryFailure:(state,{payload})=>{
            state.industryItem.error = payload;
        },


        uploadLocationIndustriesCsv:(state,{payload})=>{
            state.uploadFile.isPending = true;
            state.uploadFile.error = null;
        },
        uploadLocationIndustriesCsvSuccess:(state,{payload})=>{
            state.uploadFile.isPending = false;
            state.uploadFile.error = null;
        },
        uploadLocationIndustriesCsvFailure:(state,{payload})=>{
            state.uploadFile.isPending = false;
            state.uploadFile.error = payload;
        }

    }
});


export const industryActions = {
    setQuery: createAction("industry/setQuery"),

    fetchIndustries: createAction("industry/fetchIndustries"),
    fetchIndustriesSuccess: createAction("industry/fetchIndustriesSuccess"),
    fetchIndustriesFailure: createAction("industry/fetchIndustriesFailure"),

    fetchIndustry: createAction("industry/fetchIndustry"),
    fetchIndustrySuccess: createAction("industry/fetchIndustrySuccess"),
    fetchIndustryFailure: createAction("industry/fetchIndustryFailure"),

    createIndustry: createAction("industry/createIndustry"),
    createIndustrySuccess: createAction("industry/createIndustrySuccess"),
    createIndustryFailure: createAction("industry/createIndustryFailure"),

    updateIndustry: createAction("industry/updateIndustry"),
    updateIndustrySuccess: createAction("industry/updateIndustrySuccess"),
    updateIndustryFailure: createAction("industry/updateIndustryFailure"),

    deleteIndustry: createAction("industry/deleteIndustry"),
    deleteIndustrySuccess: createAction("industry/deleteIndustrySuccess"),
    deleteIndustryFailure: createAction("industry/deleteIndustryFailure"),

    uploadLocationIndustriesCsv: createAction("industry/uploadLocationIndustriesCsv"),
    uploadLocationIndustriesCsvSuccess: createAction("industry/uploadLocationIndustriesCsvSuccess"),
    uploadLocationIndustriesCsvFailure: createAction("industry/uploadLocationIndustriesCsvFailure"),
}

export default slice.reducer;