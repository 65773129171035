import {arahubAPI} from "config/axios";
import {API_LOCATION_URL, API_ANALYTICS_URL} from "config";

const ROUTE_LOCATION2 = 'location2';


const getScreensByCityId = async ({city_id, company_id}) =>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE_LOCATION2}`,{
            params:{
                city_id,
                company_id: company_id || null,
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getTrafficData = async (req_data) =>{
    try{
            const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}plot/people/traffic`,req_data);
            return data;
        }catch (err) {
            return Promise.reject(err.response);
        }
}

const trafficService={
    getScreensByCityId,
    getTrafficData,
}

export default trafficService;