import {API_WEBSOCKET_ARAHUB} from "config";
import {END, eventChannel} from "redux-saga";

const createWebSocketConnection = async (arabox_id) =>{
    console.log("createWebSocketConnection")
    return new Promise((resolve,reject)=>{
        const ws = new WebSocket(API_WEBSOCKET_ARAHUB);

        ws.onopen =()=>{
            try{
                ws.send(`{"task": "authorize", "uuid": "${arabox_id}"}`);
                console.log('✅ %cWebSocket is connected','color: green');
                resolve(ws);
            }catch (err) {
                reject(err);
            }
        }

        ws.onerror =(evt)=>{
            reject(evt);
        }

    });
}

const createSocketChannel=(ws)=>{
    console.log("createSocketChannel")
    return eventChannel(emit=>{

        ws.onmessage = ({data})=>{
            if(data){
                const _data = JSON.parse(data);
                emit( { type: _data?.type||null, _payload: _data||{} } );
            }else{
                emit( { type: 'NULL', payload: null } );
            }

        };

        ws.onclose = () =>{
            console.log('❌ %cWebSocket is disconnected','color: orange');
            emit(END);
        }


        return () => {
            // the subscriber must return an unsubscribe function
            // this will be invoked when the saga calls `channel.close` method
            ws.close();
        };
    });
}

const wsService={
    createWebSocketConnection,
    createSocketChannel,
}
export default wsService;