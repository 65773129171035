import {arahubAPI} from "config/axios";
import {API_ANALYTICS_URL} from "config";

const getOffers = async (query) =>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign/offer`,{
            params:{
                page: query?.page||1,
                size: query?.size||10,
                sort_by: query?.sort_by||"created_at",
                order: query?.order||"desc",
                filter: query?.filter??null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getOfferByUuid= async(uuid)=>{

    /** MOCK - data from campaign */
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign/uuid/${uuid}`,);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getOfferEstimateByUuid= async(uuid)=>{
    /** MOCK - data from campaign */
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}statistics/estimate/${uuid}`,);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const deleteOffer=async(uuid)=>{
    /** MOCK - data from campaign */
    try{
        const {data} = await arahubAPI.delete(`${API_ANALYTICS_URL}campaign/${uuid}`);
       return  data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const postDataFromCsv=async({_data, sendType})=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}campaign/import`,_data);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}


const offerService={
    getOffers,
    getOfferByUuid,
    getOfferEstimateByUuid,
    deleteOffer,
    postDataFromCsv,
}

export default offerService;