import {API_USERS_URL} from "config";
import {arahubAPI} from "config/axios";

const ROUTE = 'role';

const getRoles=async(query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}${ROUTE}`,{
            params:{
                page: query.page,
                size: query.size,
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getRole = async (id) =>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const createRole = async (roleData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_USERS_URL}${ROUTE}`,roleData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateRole=async({roleData, roleId})=>{
    /** not available right now BACKEND */
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"updateRole - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.put(`${API_USERS_URL}${ROUTE}/${roleId}`,roleData);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}

const deleteRole=async(id)=>{
    try{
        const {data} = await arahubAPI.delete(`${API_USERS_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const roleService = {
    getRoles,
    getRole,
    createRole,
    updateRole, // => not available right now
    deleteRole,
}

export default roleService;