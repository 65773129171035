import {arahubAPI} from "config/axios";
import {API_LOCATION_URL} from "config";

const ROUTE = 'city';

const getCities=async(query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}`,{
            params:{
                page: query?.page||1,
                size: query?.size||100,
                is_active: query?.is_active??null,
                filter: query?.filter??null,
                sort_by: query?.sort_by||null,
                order: query?.order||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getCity = async (id) =>{
    /** not available right now BACKEND */
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"getCity - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}/${id}`);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}


const createCity = async (cityData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}${ROUTE}`,cityData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateCity=async({cityData, cityId})=>{
    try{
        const {data} = await arahubAPI.put(`${API_LOCATION_URL}${ROUTE}/${cityId}`,cityData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const deleteCity=async(id)=>{
    /** not available right now BACKEND */
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"deleteCity - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.delete(`${API_LOCATION_URL}${ROUTE}/${id}`);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}




const cityService={
    getCities,
    getCity,
    createCity,
    updateCity,
    deleteCity,
}

export default cityService;