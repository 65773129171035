import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import trafficService from "./api";
import {trafficActions} from "./data";

function* fetchLocations({payload}){
    try{
        const data = yield call(trafficService.getScreensByCityId, payload);
        yield put(trafficActions.fetchLocationsSuccess(data));
    }catch (err) {
        yield put(trafficActions.fetchLocationsFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchTrafficData({payload}){
    try{
        const data = yield call(trafficService.getTrafficData, payload);
        yield put(trafficActions.fetchTrafficDataSuccess(data));
    }catch (err) {
        yield put(trafficActions.fetchTrafficDataFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


export default function* trafficSaga(){
    yield takeLatest(trafficActions.fetchLocations.type, fetchLocations);
    yield takeLatest(trafficActions.fetchTrafficData.type, fetchTrafficData);
}