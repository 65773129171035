import {API_USERS_URL} from "config";
import {arahubAPI} from "config/axios";

const ROUTE = 'company';

const getCompanies=async(query={})=>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}${ROUTE}`,{
            params:{
                page: query?.page??1,
                size: query?.size??50,
                sort_by: query?.sort_by||null,
                order: query?.order||null,
                filter: query?.filter||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getCompany = async (id) =>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const createCompany = async (companyData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_USERS_URL}${ROUTE}`,companyData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateCompany=async({companyData, companyId})=>{
    try{
        const {data} = await arahubAPI.put(`${API_USERS_URL}${ROUTE}/${companyId}`,companyData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const deleteCompany=async(id)=>{
    try{
        const {data} = await arahubAPI.delete(`${API_USERS_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getUserCompanySettings= async(companyId)=>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}settings/${ROUTE}/${companyId}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateUserCompanySettings=async({_settings, companyId})=>{
    try{
        const {data} = await arahubAPI.put(`${API_USERS_URL}settings/${ROUTE}/${companyId}`,_settings);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const companyService = {
    getCompanies,
    getCompany,
    createCompany,
    updateCompany,
    deleteCompany,
    getUserCompanySettings,
    updateUserCompanySettings,
}

export default companyService;