import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga'
import tokenExpirationMiddleware from "redux/root/tokenExpirationMiddleware";
import rootReducer from './RootReducer';
import rootSaga from "./RootSaga";


const saga = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: [tokenExpirationMiddleware,saga]}
);

saga.run(rootSaga);

export {store, saga};



