import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import axios from "axios";
import i18n from "i18next";


import {cToast} from "helpers";
import {API_USERS_URL} from "config";
import {userActions} from "redux/resources/user/data";
import userService from "../resources/user/api";

const tokenExpirationMiddleware = store => next => action =>{
    // const cookies = new Cookies();
    // const refreshToken = cookies.get("refresh_token");
    // const accessToken = cookies.get("access_token");
    // if(accessToken && jwtDecode(accessToken).exp < Date.now()/1000){
    //     axios.post(`${API_USERS_URL}auth/refresh-token`,{'refresh_token':refreshToken}).then(async({data})=>{
    //         if(data.access_token){
    //             await cookies.set('access_token',data.access_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
    //             next(action);
    //         }
    //     }).catch(()=>{
    //         cToast({msg: i18n.t("Session was expired"),variant:'error'});
    //         store.dispatch(userActions.logout());
    //     });
    // }else{
    //     next(action);
    // }
    next(action);
}

export default tokenExpirationMiddleware;