import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast, sessionStorageHelper} from "helpers";
import industryService from "./api";
import {industryActions} from "./data";

function* fetchIndustries({payload}){
    try{
        const data = yield call(industryService.getIndustries,payload);
        yield call(sessionStorageHelper,{type:'SET',key:'industries',value:data?.items ? data : []});
        yield put(industryActions.fetchIndustriesSuccess(data));
    }catch (err) {
        yield put(industryActions.fetchIndustriesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchIndustry({payload}){
    try{
        const data = yield call(industryService.getIndustry, payload);
        yield put(industryActions.fetchIndustrySuccess(data));
    }catch (err) {
        yield put(industryActions.fetchIndustryFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* createIndustry({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(industryService.createIndustry,_data);
        yield put(industryActions.createIndustrySuccess(data));
        yield navigate('/industry');
        yield cToast({msg: i18n.t("Industry was created"),variant:'success'});
    }catch (err) {
        yield put(industryActions.createIndustryFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* updateIndustry({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(industryService.updateIndustry,{
            industryData: _data,
            industryId: id,
        });
        yield put(industryActions.updateIndustrySuccess(data));
        yield navigate('/industry');
        yield cToast({msg: i18n.t("Industry was updated"),variant:'success'});
    }catch (err) {
        yield put(industryActions.updateIndustryFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* deleteIndustry({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(industryService.deleteIndustry,id);
        yield put(industryActions.deleteIndustrySuccess(data));
        yield navigate('/industry');
        yield cToast({msg: i18n.t("Industry was deleted"),variant:'warning'});
    }catch (err) {
        yield put(industryActions.deleteIndustryFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* uploadFile({payload}){
    const{
        file,
        navigate,
    }=payload;
    try{
        const fData = new FormData();
        fData.append("file", file.binaryFile);
        const data = yield call(industryService.uploadLocationIndustriesCsv,fData);
        yield put(industryActions.uploadLocationIndustriesCsvSuccess(data));
        yield navigate('/industry');
        yield cToast({msg: i18n.t("File was uploaded"),variant:'success'});
    }catch (err) {
        yield put(industryActions.uploadLocationIndustriesCsvFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* industrySaga(){
    yield takeLatest(industryActions.fetchIndustries.type, fetchIndustries);
    yield takeLatest(industryActions.fetchIndustry.type, fetchIndustry);
    yield takeLatest(industryActions.createIndustry.type, createIndustry);
    yield takeLatest(industryActions.updateIndustry.type, updateIndustry);
    yield takeLatest(industryActions.deleteIndustry.type, deleteIndustry);
    yield takeLatest(industryActions.uploadLocationIndustriesCsv.type, uploadFile);
}