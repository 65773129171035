import {createAction, createSlice} from "@reduxjs/toolkit"


const initialState = {
    locations:{
        isPending: false,
        error: null,
        locationItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
    },
    locationItem:{
        isPending: false,
        error: null,
        item:{},
    },
    locationPanoramaImagesLis:{
        isPending: false,
        error: null,
        images:[],
    },
    locationPanoramaImage:{
        isPending: false,
        error: null,
        locationImage: null,
    }
}

const slice = createSlice({
    name: 'location',
    initialState,
    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                fieldFilter,
                sort_by,
                order,
            }=payload;
            state.locations.query.size = pageSize;
            state.locations.query.page = page;
            state.locations.query.filter = filter;
            state.locations.query.fieldFilter = fieldFilter;
            state.locations.query.sort_by = sort_by;
            state.locations.query.order = order;
        },


        fetchLocations:(state,{payload})=>{
            state.locations.error = null;
            state.locations.locationItems = [];
            state.locations.isPending = true;
        },
        fetchLocationsSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.locations.query.total = total;
            state.locations.query.page = page;
            state.locations.query.pages = pages;
            state.locations.query.size = size;

            state.locations.error = null;
            state.locations.locationItems = items;
            state.locations.isPending = false;
        },
        fetchLocationsFailure:(state,{payload})=>{
            state.locations.error = payload;
            state.locations.locationItems = [];
            state.locations.isPending = false;
        },


        fetchLocation:(state,{payload})=>{
            state.locationItem.error = null;
            state.locationItem.item = {};
            state.locationItem.isPending = true;
        },
        fetchLocationSuccess:(state,{payload})=>{
            state.locationItem.error = null;
            state.locationItem.item = payload;
            state.locationItem.isPending = false;
        },
        fetchLocationFailure:(state,{payload})=>{
            state.locationItem.error = payload;
            state.locationItem.item = {};
            state.locationItem.isPending = false;
        },


        createLocation:(state,{payload})=>{
            //
        },
        createLocationSuccess:(state,{payload})=>{
            //
        },
        createLocationFailure:(state,{payload})=>{
            state.locationItem.error = payload;
        },


        updateLocation:(state,{payload})=>{
            //
        },
        updateLocationSuccess:(state,{payload})=>{
            //
        },
        updateLocationFailure:(state,{payload})=>{
            state.locationItem.error = payload;
        },


        deleteLocation:(state,{payload})=>{
            //
        },
        deleteLocationSuccess:(state,{payload})=>{
            //
        },
        deleteLocationFailure:(state,{payload})=>{
            state.locationItem.error = payload;
        },


        fetchLocationPanoramaImagesList:(state,{payload})=>{
            console.log("tutaj")
            state.locationPanoramaImagesLis.isPending = true;
            state.locationPanoramaImagesLis.images = [];
        },
        fetchLocationPanoramaImagesListSuccess:(state,{payload})=>{
            state.locationPanoramaImagesLis.images = payload?.panoramas??[];
            state.locationPanoramaImagesLis.isPending = false;
        },
        fetchLocationPanoramaImagesListFailure:(state,{payload})=>{
            state.locationPanoramaImagesLis.error = payload;
            state.locationPanoramaImagesLis.isPending = false;
        },



        fetchLocationPanoramaImage:(state,{payload})=>{
            state.locationPanoramaImage.isPending = true;
            state.locationPanoramaImage.locationImage = null;
            state.locationPanoramaImage.error = null;
        },
        fetchLocationPanoramaImageSuccess:(state,{payload})=>{
            state.locationPanoramaImage.locationImage = payload?.image_sign_url??null;
            state.locationPanoramaImage.isPending = false;
            state.locationPanoramaImage.error = null;
        },
        fetchLocationPanoramaImageFailure:(state,{payload})=>{
            state.locationPanoramaImage.error = payload;
            state.locationPanoramaImage.locationImage = null;
            state.locationPanoramaImage.isPending = false;
        },

        updateLocationPanoramaImage:(state,{payload})=>{
            state.locationPanoramaImage.isPending = true;
        },
        updateLocationPanoramaImageSuccess:(state,{payload})=>{
            state.locationPanoramaImage.locationImage = payload?.image_sign_url??null;
            state.locationPanoramaImage.error = null;
            state.locationPanoramaImage.isPending = false;
        },
        updateLocationPanoramaImageFailure:(state,{payload})=>{
            state.locationPanoramaImage.isPending = false;
        },
    }
});


export const locationActions = {
    setQuery: createAction("location/setQuery"),

    fetchLocations: createAction("location/fetchLocations"),
    fetchLocationsSuccess: createAction("location/fetchLocationsSuccess"),
    fetchLocationsFailure: createAction("location/fetchLocationsFailure"),

    fetchLocation: createAction("location/fetchLocation"),
    fetchLocationSuccess: createAction("location/fetchLocationSuccess"),
    fetchLocationFailure: createAction("location/fetchLocationFailure"),

    createLocation: createAction("location/createLocation"),
    createLocationSuccess: createAction("location/createLocationSuccess"),
    createLocationFailure: createAction("location/createLocationFailure"),

    updateLocation: createAction("location/updateLocation"),
    updateLocationSuccess: createAction("location/updateLocationSuccess"),
    updateLocationFailure: createAction("location/updateLocationFailure"),

    deleteLocation: createAction("location/deleteLocation"),
    deleteLocationSuccess: createAction("location/deleteLocationSuccess"),
    deleteLocationFailure: createAction("location/deleteLocationFailure"),

    fetchLocationPanoramaImagesList: createAction("location/fetchLocationPanoramaImagesList"),
    fetchLocationPanoramaImagesListSuccess: createAction("location/fetchLocationPanoramaImagesListSuccess"),
    fetchLocationPanoramaImagesListFailure: createAction("location/fetchLocationPanoramaImagesListFailure"),

    fetchLocationPanoramaImage: createAction("location/fetchLocationPanoramaImage"),
    fetchLocationPanoramaImageSuccess: createAction("location/fetchLocationPanoramaImageSuccess"),
    fetchLocationPanoramaImageFailure: createAction("location/fetchLocationPanoramaImageFailure"),

    updateLocationPanoramaImage: createAction("location/updateLocationPanoramaImage"),
    updateLocationPanoramaImageSuccess: createAction("location/updateLocationPanoramaImageSuccess"),
    updateLocationPanoramaImageFailure: createAction("location/updateLocationPanoramaImageFailure"),
}

export default slice.reducer;