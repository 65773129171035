import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import companyService from "./api";
import {companyActions} from "./data";



function* fetchCompanies({payload}){
    try{
        const data = yield call(companyService.getCompanies,payload);
        yield put(companyActions.fetchCompaniesSuccess(data));
    }catch (err) {
        yield put(companyActions.fetchCompaniesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchCompany({payload}){
    try{
        const data = yield call(companyService.getCompany, payload);
        yield put(companyActions.fetchCompanySuccess(data));
    }catch (err) {
        yield put(companyActions.fetchCompanyFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* createCompany({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(companyService.createCompany,_data);
        yield put(companyActions.createCompanySuccess(data));
        yield navigate('/company');
        yield cToast({msg: i18n.t("Company was created"),variant:'success'});
    }catch (err) {
        yield put(companyActions.createCompanyFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* updateCompany({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(companyService.updateCompany,{
            companyData: _data,
            companyId: id,
        });
        yield put(companyActions.updateCompanySuccess(data));
        yield navigate('/company');
        yield cToast({msg: i18n.t("Company was updated"),variant:'success'});
    }catch (err) {
        yield put(companyActions.updateCompanyFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* deleteCompany({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(companyService.deleteCompany,id);
        yield put(companyActions.deleteCompanySuccess(data));
        yield navigate('/company');
        yield cToast({msg: i18n.t("Company was deleted"),variant:'warning'});
    }catch (err) {
        yield put(companyActions.deleteCompanyFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchUserCompanySettings({payload}){
    try{
        const data = yield call(companyService.getUserCompanySettings,payload);
        yield put(companyActions.fetchUserCompanySettingsSuccess(data));
    }catch (err) {
        yield put(companyActions.fetchUserCompanySettingsFailure(err))
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* updateUserCompanySettings({payload}){
    try{
        const data = yield call(companyService.updateUserCompanySettings,payload);
        yield put(companyActions.updateUserCompanySettingsSuccess(data));
        yield put(companyActions.fetchUserCompanySettings(payload.companyId));
        yield cToast({msg: i18n.t("Company settings was updated"),variant:'success'});
    }catch (err) {
        yield put(companyActions.updateUserCompanySettingsFailure(err))
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* companySaga(){
    yield takeLatest(companyActions.fetchCompanies.type, fetchCompanies);
    yield takeLatest(companyActions.fetchCompany.type, fetchCompany);
    yield takeLatest(companyActions.createCompany.type, createCompany);
    yield takeLatest(companyActions.updateCompany.type, updateCompany);
    yield takeLatest(companyActions.deleteCompany.type, deleteCompany);
    yield takeLatest(companyActions.fetchUserCompanySettings.type, fetchUserCompanySettings);
    yield takeLatest(companyActions.updateUserCompanySettings.type, updateUserCompanySettings);
}