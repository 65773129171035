import {call, put, takeLatest} from "redux-saga/effects";
import i18n from "i18next";
import {cToast} from "helpers";
import araboxLiveService from "./api";
import {araboxLiveActions} from "./data";


function* fetchLiveTraffic({payload}){
    try{
        const data = yield call(araboxLiveService.getLiveAraboxTraffic, payload);
        yield put(araboxLiveActions.fetchLiveTrafficSuccess(data));
    }catch (err) {
        yield put(araboxLiveActions.fetchLiveTrafficFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchTodayHourlyStats({payload}){
    try{
        const data = yield call(araboxLiveService.getTodayAraboxRawData, payload);
        yield put(araboxLiveActions.fetchTodayHourlyStatsSuccess(data));
    }catch (err) {
        yield put(araboxLiveActions.fetchTodayHourlyStatsFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* fetchAraboxZones({payload}){
    try{
        const data = yield call(araboxLiveService.getAraboxZones,payload);
        yield put(araboxLiveActions.fetchAraboxZonesSuccess(data));
    }catch (err) {
        yield put(araboxLiveActions.fetchAraboxZonesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchAraboxHeatmap({payload}){
    try{
        const data = yield call(araboxLiveService.getAraboxHeatmap,payload);
        yield put(araboxLiveActions.fetchAraboxHeatmapSuccess(data));
    }catch (err) {
        yield put(araboxLiveActions.fetchAraboxHeatmapFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* araboxLiveSaga(){
    yield takeLatest(araboxLiveActions.fetchLiveTraffic.type, fetchLiveTraffic);
    yield takeLatest(araboxLiveActions.fetchTodayHourlyStats.type, fetchTodayHourlyStats);
    yield takeLatest(araboxLiveActions.fetchAraboxZones.type, fetchAraboxZones);
    yield takeLatest(araboxLiveActions.fetchAraboxHeatmap.type, fetchAraboxHeatmap);
}