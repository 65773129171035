import {call, put, takeLatest} from "redux-saga/effects";
import i18n from "i18next";
import {cToast, sessionStorageHelper} from "helpers";
import yourAraboxesService from "./api";
import {yourAraboxesActions} from "./data";


function* fetchCities({payload}){
    try{
        const data = yield call(yourAraboxesService.getCities,payload);
        if(data?.length>0){
            const _data = data.reduce((acc,item)=>{
                acc.push({
                    ...item,
                    isChecked: item.is_active,
                    __isVisible: item.is_active,
                })
                return acc;
            },[]);
            yield put(yourAraboxesActions.fetchCitiesSuccess(_data));
            yield call(sessionStorageHelper,{
                type:'SET',
                key:'your-araboxes-cities',
                value:{
                    companyId: payload,
                    items: data,
                }});
        }else{
            yield put(yourAraboxesActions.fetchCitiesSuccess(data));
        }
    }catch (err) {
        yield put(yourAraboxesActions.fetchCitiesFailure(err));
       yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchLocationsDevices({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationsDevices,payload);
        yield put(yourAraboxesActions.fetchLocationsDevicesSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationsDevicesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchLocationsDevicesStats({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationsDevicesStats,payload);
        yield put(yourAraboxesActions.fetchLocationsDevicesStatsSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationsDevicesStatsFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

function* fetchLocationsDevicesStatsTotal({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationsDevicesStatsTotal,payload);
        yield put(yourAraboxesActions.fetchLocationsDevicesStatsTotalSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationsDevicesStatsTotalFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

function* fetchLocationDevicesPlot({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationsDevicesPlot,payload);
        yield put(yourAraboxesActions.fetchLocationDevicesPlotSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationDevicesPlotFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

function* fetchLocationDevicesPeopleNetGrosPlot({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationDevicesPeopleNetGrossPlot,payload);
        yield put(yourAraboxesActions.fetchLocationDevicesPeopleNetGrosPlotSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationDevicesPeopleNetGrosPlotFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}


function* fetchLocationDevicesDayToDayPlot({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationDevicesDayToDayPlot,payload);
        yield put(yourAraboxesActions.fetchLocationDevicesDayToDayPlotSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationDevicesDayToDayPlotFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}


function* fetchItemLocationDevicePlot({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationsDevicesPlot,payload);
        yield put(yourAraboxesActions.fetchItemLocationDevicePlotSuccess(data));
    }catch (err) {
        yield put(yourAraboxesActions.fetchItemLocationDevicePlotFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

// age-gender plot
function* fetchLocationDevicesAgeGenderPlot({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationDevicesAgeGenderPlot,payload);
        yield put(yourAraboxesActions.fetchLocationDevicesAgeGenderPlotSuccess({...data, aggregation: payload.aggregation}));
    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationDevicesAgeGenderPlotFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

function* fetchAraboxViewImage({payload}) {
    try {
        const data = yield call(yourAraboxesService.getAraboxViewImage, payload);
        yield put(yourAraboxesActions.fetchAraboxViewImageSuccess(data));
    } catch (err) {
        yield put(yourAraboxesActions.fetchAraboxViewImageFailure(err));
        yield cToast({msg: err.data.detail, variant: 'error'});
    }
}


function* fetchLocationReport({payload}){
    try{
        const data = yield call(yourAraboxesService.getLocationReport,payload);
        yield put(yourAraboxesActions.fetchLocationReportSuccess(data));

    }catch (err) {
        yield put(yourAraboxesActions.fetchLocationReportFailure(err));
        yield cToast({msg: err.data.detail,variant:'error'});
    }
}

export default function* yourAraboxesSaga(){
    yield takeLatest(yourAraboxesActions.fetchCities.type, fetchCities);
    yield takeLatest(yourAraboxesActions.fetchLocationsDevices.type, fetchLocationsDevices);
    yield takeLatest(yourAraboxesActions.fetchLocationsDevicesStats.type, fetchLocationsDevicesStats);
    yield takeLatest(yourAraboxesActions.fetchLocationsDevicesStatsTotal.type, fetchLocationsDevicesStatsTotal);
    yield takeLatest(yourAraboxesActions.fetchLocationDevicesPlot.type, fetchLocationDevicesPlot);
    yield takeLatest(yourAraboxesActions.fetchLocationDevicesPeopleNetGrosPlot.type, fetchLocationDevicesPeopleNetGrosPlot);
    yield takeLatest(yourAraboxesActions.fetchLocationDevicesDayToDayPlot.type, fetchLocationDevicesDayToDayPlot);
    yield takeLatest(yourAraboxesActions.fetchItemLocationDevicePlot.type, fetchItemLocationDevicePlot);
    yield takeLatest(yourAraboxesActions.fetchLocationDevicesAgeGenderPlot.type, fetchLocationDevicesAgeGenderPlot);
    yield takeLatest(yourAraboxesActions.fetchAraboxViewImage.type, fetchAraboxViewImage);
    yield takeLatest(yourAraboxesActions.fetchLocationReport.type, fetchLocationReport);
}