import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import {gemiusActions} from "./data";
import gemiusService from "./api";


function* fetchGemiusCampaigns({payload}){
    try{
        const data = yield call(gemiusService.getGemiusCampaigns,payload);

        yield put(gemiusActions.fetchGemiusCampaignsSuccess(data?.campaigns||[]));
    }catch (err) {
        yield put(gemiusActions.fetchGemiusCampaignsFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* gemiusSaga(){
    yield takeLatest(gemiusActions.fetchGemiusCampaigns.type, fetchGemiusCampaigns)
}