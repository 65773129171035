import {call, put, takeLatest} from "redux-saga/effects";
import i18n from "i18next";
import {cToast, campaignLengthInDays, sessionStorageHelper} from "helpers";
import campaignService from "./api";
import {campaignActions} from "./data";

function* fetchCampaigns({payload}){
    try{
        const data = yield call(campaignService.getCampaigns,payload);
        const reducedItems = yield data.items.reduce((acc,item)=>{
            acc.push({
                ...item,
                date_from : new Date(item.date_from),
                date_to : new Date(item.date_to),
                lengthInDays: campaignLengthInDays(item.date_from, item.date_to),
            });
            return acc;
        },[]);

        yield put(campaignActions.fetchCampaignsSuccess({
            items: reducedItems,
            query:{
                page: data.page,
                pages: data.pages,
                size: data.size,
                total: data.total,
            },
        }));
    }catch (err) {
        yield put(campaignActions.fetchCampaignsFailure(err))
    }
}

function* fetchCampaign({payload}){

    if(payload.isFromShared){
        try{
            const data = yield call(campaignService.getSharedCampaignByUuid,payload.uuid);
            yield put(campaignActions.fetchCampaignSuccess(data));

            // shared should be
            yield put(campaignActions.fetchCampaignEstimates(payload));

            // shared should be
            yield put(campaignActions.fetchCampaignStatistics(payload));

            if(data.gemius_uuid){ // ?
                yield put(campaignActions.fetchGemiusStatistics(data.gemius_uuid))
            }
        }catch (err) {
            yield put(campaignActions.fetchCampaignFailure(err))
        }
    }else{
        try{
            const data = yield call(campaignService.getCampaignByUuid, {uuid: payload.uuid, is_grp: payload.is_grp});
            yield put(campaignActions.fetchCampaignSuccess(data));
            if(data?.spots?.length>0 && data?.spots?.[0]?.spot_file_persist && payload?.formType === "EDIT") {
                yield put(campaignActions.fetchCampaignSpots({
                    uuid: payload.uuid, spotId: data.spots[data.spots.length-1].id,
                    fileName: data.spots[data.spots.length-1].file_name
                }))
            }
            yield put(campaignActions.fetchCampaignEstimates(payload));
            yield put(campaignActions.fetchCampaignStatistics(payload));
            if(data.gemius_uuid){
                yield put(campaignActions.fetchGemiusStatistics(data.gemius_uuid))
            }
        }catch (err) {
            yield put(campaignActions.fetchCampaignFailure(err))
        }
    }

}

function* fetchCampaignStatistics({payload}){
    if(payload.isFromShared){
        try{
            const data = yield call(campaignService.getSharedCampaignStatisticsByUuid,payload.uuid);
            yield put(campaignActions.fetchCampaignStatisticsSuccess(data));
        }catch (err) {
            yield put(campaignActions.fetchCampaignStatisticsFailure(err))
        }
    }else{
        try{
            const data = yield call(campaignService.getCampaignStatistics,payload.uuid);
            yield put(campaignActions.fetchCampaignStatisticsSuccess(data));
        }catch (err) {
            yield put(campaignActions.fetchCampaignStatisticsFailure(err))
        }
    }

}

function* fetchCampaignEstimates({payload}){
    if(payload.isFromShared){
        try{
            const data = yield call(campaignService.getSharedCampaignEstimateByUuid,payload.uuid);
            yield put(campaignActions.fetchCampaignEstimatesSuccess(data));
        }catch (err) {
            yield put(campaignActions.fetchCampaignEstimatesFailure(err))
        }
    }else{
        try{
            const data = yield call(campaignService.getCampaignEstimates,payload.uuid);
            yield put(campaignActions.fetchCampaignEstimatesSuccess(data));
        }catch (err) {
            yield put(campaignActions.fetchCampaignEstimatesFailure(err))
        }
    }

}

function* createCampaign({payload}){
    const{
        formData,
        navigate,
        spotFile,
        _submitType,
    }=payload;
    if(_submitType === 'CREATE'){
        try{
            const data = yield call(campaignService.createCampaign,formData);
            yield put(campaignActions.createCampaignSuccess(data));
            yield navigate(`/campaign/preview/${data.uuid}`);
            yield cToast({msg: i18n.t("Campaign was created"),variant:'success'});
        }catch (err) {
            yield cToast({msg: `${err?.status||500} - ${err?.data?.detail||"Something went wrong"}`,variant:'error'});
            yield put(campaignActions.createCampaignFailure(err))
        }
    }else if(_submitType === 'CREATE_FILE'){
        try{
            const data = yield call(campaignService.createCampaign,formData);
            yield put(campaignActions.createCampaignSuccess(data));

            const fData = new FormData();
            fData.append("file",spotFile.binaryFile);

            yield put(campaignActions.uploadSpotFile({formData:fData, uuid:data.uuid, spotId: data.spots[0].id}));

            yield navigate(`/campaign/preview/${data.uuid}`);
            yield cToast({msg: i18n.t("Campaign was created"),variant:'success'});
        }catch (err) {
            yield cToast({msg: `${err?.status||500} - ${err?.data?.detail||"Something went wrong"}`,variant:'error'});
            yield put(campaignActions.createCampaignFailure(err))
        }
    }
}

function* updateCampaign({payload}){
    const{
        formData,
        _submitType,
        navigate,
        spotFile,
        spotId,
        customMsg,
        isFromPreview,
    }=payload;
    if(_submitType === 'UPDATE'){
        try{
            const data = yield call(campaignService.updateCampaign, formData);
            yield put(campaignActions.updateCampaignSuccess(data));

            if(isFromPreview){
                yield (put(campaignActions.fetchCampaign({formType:"PREVIEW", uuid: data.uuid})));
            }else{
                yield navigate(`/campaign/preview/${data.uuid}`);
            }
            if(customMsg){
                yield cToast({msg: i18n.t(customMsg),variant:'success'});
            }else{
                yield cToast({msg: i18n.t("Campaign was updated"),variant:'success'});
            }
        }catch (err) {
            yield cToast({msg: `${err?.status||500} - ${err?.data?.detail||"Something went wrong"}`,variant:'error'});
            yield put(campaignActions.updateCampaignFailure(err));
        }
    }else if(_submitType === 'UPDATE_FILE'){
        try{
            const fData = new FormData();
            fData.append("file",spotFile.binaryFile);

            const data = yield call(campaignService.updateCampaign,formData);
            yield put(campaignActions.updateCampaignSuccess(data));
            yield put(campaignActions.uploadSpotFile({formData:fData, uuid:formData.uuid, spotId}));

            yield navigate(`/campaign/preview/${data.uuid}`);
            // yield put(campaignActions.fetchCampaign({formType:"EDIT", uuid: data.uuid}));

            if(customMsg){
                yield cToast({msg: i18n.t(customMsg),variant:'success'});
            }else{
                yield cToast({msg: i18n.t("Campaign was updated"),variant:'success'});
            }
        }catch (err) {
            yield cToast({msg: `${err?.status||500} - ${err?.data?.detail||"Something went wrong"}`,variant:'error'});
            yield put(campaignActions.updateCampaignFailure(err));
        }


    }
}

function* deleteCampaign({payload}){
    const{
        uuid,
        navigate
    }=payload;
    try{
        const data = yield call(campaignService.deleteCampaign,uuid);
        yield put(campaignActions.deleteCampaignSuccess(data));
        yield navigate('/campaign');
        yield cToast({msg: i18n.t("Campaign was deleted"),variant:'warning'});
    }catch (err) {
        yield put(campaignActions.deleteCampaignFailure(err))
    }
}

function* fetchCampaignSpots({payload:{uuid, spotId, fileName}}){
    try{
        const data = yield call(campaignService.getSpotById,{uuid, spotId});
        yield put(campaignActions.fetchCampaignSpotsSuccess({
            link: data.sign_url,
            fileName,
            id: spotId
        }));

    }catch (err) {
        yield put(campaignActions.fetchCampaignSpotsFailure(err))
    }
}

function* uploadSpotFile({payload}){
    try{
        const data = yield call(campaignService.uploadSpotFile,payload);
        yield put(campaignActions.uploadSpotFileSuccess(data));
        yield cToast({msg: i18n.t("Spot file was uploaded"),variant:'success'});
    }catch (err) {
        yield put(campaignActions.uploadSpotFileFailure(err))
    }
}

function* fetchCitiesWithCount({payload}){
    try{
        const data = yield call(campaignService.getCitiesWithSpotsCount,payload);
        yield call(sessionStorageHelper,{type:'SET',key:'screen_cities',value:data});
        if(data){
           const dat = yield call(campaignService.getUsedCityScreens,payload);
           if(dat){

               const reduced = data.number_of_screens_per_city.reduce((acc,item)=>{
                   acc.total_number_of_screens = (data.total_number_of_screens - dat.total_number_of_screens) < 0 ? 0 : (data.total_number_of_screens - dat.total_number_of_screens);
                   acc.number_of_screens_per_city.push({
                      city_id: item.city_id,
                      city_name: item.city_name,
                      number_of_screens:
                          dat.number_of_screens_per_city.some(x=> x.city_id === item.city_id)
                          ? ((item.number_of_screens - dat.number_of_screens_per_city.find(x=> x.city_id === item.city_id).number_of_screens) < 0 ? 0 : (item.number_of_screens - dat.number_of_screens_per_city.find(x=> x.city_id === item.city_id).number_of_screens))
                          : item.number_of_screens,
                   });
                   return acc;
               },{
                   total_number_of_screens:0,
                   number_of_screens_per_city:[],
               });

               yield put(campaignActions.fetchCitiesWithCountSuccess(reduced));
           }else{
               yield put(campaignActions.fetchCitiesWithCountSuccess(data));
           }
        }else{
            yield put(campaignActions.fetchCitiesWithCountSuccess(data));
        }
    }catch (err) {
        yield put(campaignActions.fetchCitiesWithCountFailure(err))
    }
}

function* fetchUsedCityScreens({payload}){
    try{
       const data = yield call(campaignService.getUsedCityScreens,payload);
        yield put(campaignActions.fetchUsedCityScreensSuccess(data));
    }catch (err) {
        yield put(campaignActions.fetchUsedCityScreensFailure(err))
    }
}

function* fetchGemiusStatistics({payload}){
    try{
        // fetch general
        const data = yield call(campaignService.getGemiusStatistics,{timeDivision:"General", gemius_uuid:payload});
        yield put(campaignActions.fetchGemiusStatisticsSuccess(data));
        // fetch hour
        const data2 = yield call(campaignService.getGemiusStatistics,{timeDivision:"Hour", gemius_uuid:payload});
        yield put(campaignActions.fetchGemiusStatisticsSuccess(data2));
    }catch (err) {
        yield put(campaignActions.fetchGemiusStatisticsFailure(err))
    }
}

function* fetchEstimatedAudience({payload}){
    try{
        const data = yield call(campaignService.getEstimatedAudience, payload);
        yield put(campaignActions.fetchEstimatedAudienceSuccess(data));
    }catch (err) {
        // yield cToast({msg: `${err?.status||500} - ${err?.data?.detail||"Estimation error"}`,variant:'error'});
        yield put(campaignActions.fetchEstimatedAudienceFailure(err))
    }
}

function* shareCampaignHelper({payload}){
    try{
        const data = yield call(campaignService.shareCampaignHelper, payload);
        yield put(campaignActions.shareCampaignHelperSuccess(data));
        yield payload.navigate(0);
    }catch (err) {
        yield cToast({msg: i18n.t(err?.data?.detail||"Something wen wrong"),variant:'error'});
        yield put(campaignActions.shareCampaignHelperFailure(err))
    }
}

function* confirmCampaign({payload}){
    try{
        const data = yield call(campaignService.confirmCampaign, payload);
        // yield put(campaignActions.shareCampaignHelperSuccess(data));
        // yield payload.navigate(0);
        yield (put(campaignActions.fetchCampaign({formType:"EDIT", uuid: data.uuid})))
        yield cToast({msg: i18n.t("Campaign was confirmed"),variant:'success'});
    }catch (err) {
        yield cToast({msg: i18n.t(err?.data?.detail||"Something wen wrong"),variant:'error'});
        // yield put(campaignActions.shareCampaignHelperFailure(err))
    }
}

function* fetchCampaignAudienceStats({payload}){
    try{
        const data = yield call(campaignService.getCampaignAudienceStats, payload);
        yield put(campaignActions.fetchCampaignAudienceStatsSuccess(data))
    }catch (err) {
        yield put(campaignActions.fetchCampaignAudienceStatsFailure(err))
        yield cToast({msg: i18n.t(err?.data?.detail||"Something wen wrong"),variant:'error'});
    }
}

export default function* campaignSaga () {
    yield takeLatest(campaignActions.fetchCampaigns.type, fetchCampaigns);
    yield takeLatest(campaignActions.fetchCampaign.type, fetchCampaign);
    yield takeLatest(campaignActions.fetchCampaignStatistics.type, fetchCampaignStatistics);
    yield takeLatest(campaignActions.fetchCampaignEstimates.type, fetchCampaignEstimates);
    yield takeLatest(campaignActions.createCampaign.type, createCampaign);
    yield takeLatest(campaignActions.deleteCampaign.type, deleteCampaign);
    yield takeLatest(campaignActions.updateCampaign.type, updateCampaign);
    yield takeLatest(campaignActions.fetchCampaignSpots.type, fetchCampaignSpots);
    yield takeLatest(campaignActions.uploadSpotFile.type, uploadSpotFile);
    yield takeLatest(campaignActions.fetchCitiesWithCount.type, fetchCitiesWithCount);
    yield takeLatest(campaignActions.fetchUsedCityScreens.type, fetchUsedCityScreens);
    yield takeLatest(campaignActions.fetchGemiusStatistics.type, fetchGemiusStatistics);
    yield takeLatest(campaignActions.fetchEstimatedAudience.type, fetchEstimatedAudience);
    yield takeLatest(campaignActions.shareCampaignHelper.type, shareCampaignHelper);
    yield takeLatest(campaignActions.confirmCampaign.type, confirmCampaign);
    yield takeLatest(campaignActions.fetchCampaignAudienceStats.type, fetchCampaignAudienceStats);
}