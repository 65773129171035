import { combineReducers } from "@reduxjs/toolkit";
import CustomizerReducer from '../resources/customizer/CustomizerReducer';
import userReducer from '../resources/user/data';
import campaignReducer from '../resources/campaign/data';
import offerReducer from '../resources/offer/data';
import cityReducer from "../resources/city/data";
import roleReducer from "../resources/role/data";
import companyReducer from "../resources/company/data";
import locationReducer from "../resources/location/data";
import deviceReducer from "../resources/device/data";
import industryReducer from "../resources/industry/data";
import yourAraboxesReducer from "../resources/youraraboxes/data";
import araboxLiveReducer from "../resources/araboxlive/data";
import gemiusReducer from "../resources/gemius/data";
import wsReducer from "../resources/_websocket/data";
import trafficReducer from "../resources/traffic/data";


const rootReducer = combineReducers({
    customizer: CustomizerReducer,
    user: userReducer,
    campaign: campaignReducer,
    offer: offerReducer,
    city: cityReducer,
    role: roleReducer,
    company: companyReducer,
    location: locationReducer,
    device: deviceReducer,
    industry: industryReducer,
    youraraboxes: yourAraboxesReducer,
    araboxlive: araboxLiveReducer,
    gemius: gemiusReducer,
    traffic: trafficReducer,

    _ws: wsReducer,
});
export default rootReducer;