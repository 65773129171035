import {createAction, createSlice, current} from "@reduxjs/toolkit"
import Cookies from 'universal-cookie';
import {cToast, sessionStorageHelper} from "helpers";
import i18n from "i18next";

const _user = JSON.parse(localStorage.getItem("user"));
const cookies = new Cookies();

const initialState = {
    account: _user || {},
    isLogged: !!_user?.id || false,

    isPending: false,
    error: null,

    users:{
        isPending: false,
        error: null,
        query:{
            page: 1,
            pages:1,
            size:10,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
        userItems:[],
    },
    userItem:{
        isPending: false,
        error: null,
        item:{},
    },
    changePassword:{
        isPending: false,
        error: null,
        success: null,
    }
}

const slice = createSlice({
    name: "user",
    initialState,
    reducers:{
        authenticate: (state)=>{
            state.isPending = true;
        },
        authenticateSuccess: (state, {payload}) => {
            state.account = payload.user;
            state.isLogged = true;
            if(payload.refresh_token){
               cookies.set('refresh_token',payload.refresh_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
               cookies.set('access_token',payload.access_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
            }
            //
            if(payload.user){
                localStorage.setItem("user", JSON.stringify(payload.user));
            }
            state.isPending =false;
        },
        authenticateFailure: (state) => {
            state.isLogged = false;
            state.isPending =false;
        },


        logout: (state) => {
            //
        },
        logoutSuccess: (state) => {
            localStorage.removeItem("user");
            sessionStorageHelper({type: 'CLEAR_ALL'}).then();
            cookies.remove('refresh_token',{ path: '/' });
            cookies.remove('access_token',{ path: '/' });
            state.isLogged = false;
            state.access_token = null;
            state.account = {};
            cToast({msg: i18n.t("Logged out"),variant:'success',toastId: 2});

        },
        logoutFailure: (state) => {
            cookies.remove('refresh_token',{ path: '/' });
            cookies.remove('access_token',{ path: '/' });
            localStorage.removeItem("user");
            state.isLogged = false;
            state.access_token = null;
            state.account = {};
        },


        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                fieldFilter,
                sort_by,
                order,
            }=payload;
            state.users.query.size = pageSize;
            state.users.query.page = page;
            state.users.query.filter = filter;
            state.users.query.fieldFilter = fieldFilter;
            state.users.query.sort_by = sort_by;
            state.users.query.order = order;
        },



        fetchUser: (state) =>{
            state.userItem.error = null;
            state.userItem.isPending = true;
            state.userItem.item = {};
        },
        fetchUserSuccess: (state,{payload}) =>{
            state.userItem.item = payload;
            state.userItem.isPending = false;
            state.userItem.error = null;
        },
        fetchUserFailure: (state,{payload}) =>{
            state.userItem.error = payload;
            state.userItem.item = [];
            state.userItem.isPending = false;
        },



        fetchUsers:(state,{payload})=>{
            state.users.error = null;
            state.users.isPending = true;
            state.users.userItems = [];
        },
        fetchUsersSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.users.query.total = total;
            state.users.query.page = page;
            state.users.query.pages = pages;
            state.users.query.size = size;


            state.users.userItems = items;
            state.users.isPending = false;
            state.users.error = null;
        },
        fetchUsersFailure:(state,{payload})=>{
            state.users.error = payload;
            state.users.userItems = [];
            state.users.isPending = false;
        },


        createUser:(state,{payload})=>{
           // state.userItems.isPending = true;
        },
        createUserSuccess:(state,{payload})=>{
            // state.userItems.isPending = false;
        },
        createUserFailure:(state,{payload})=>{
            state.userItems.error = payload;
            // state.userItems.isPending = false;
        },


        updateUser:(state,{payload})=>{
            // state.userItems.isPending = true
        },
        updateUserSuccess:(state,{payload})=>{
            // state.userItems.isPending = false;
            // update me
            if(payload?.uuid === state.account.uuid){
                state.account = payload;
                localStorage.setItem("user", JSON.stringify(payload));
            }

        },
        updateUserFailure:(state,{payload})=>{
            state.userItems.error = payload;
            // state.userItems.isPending = false;
        },



        deleteUser:(state,{payload})=>{
            // state.userItems.isPending = true;
        },
        deleteUserSuccess:(state,{payload})=>{
            // state.userItems.isPending = false;
        },
        deleteUserFailure:(state,{payload})=>{
            state.userItems.error = payload;
            // state.userItems.isPending = false;
        },

        refreshAuthToken:(state,{payload})=>{
            //
        },
        refreshAuthTokenSuccess:(state,{payload})=>{
            //
        },
        refreshAuthTokenFailure:(state,{payload})=>{
            //
        },

        changePassword:(state,{payload})=>{
            state.changePassword.isPending = true;
            state.changePassword.error = null;
            state.changePassword.success = null;
        },
        changePasswordSuccess:(state,{payload})=>{
            state.changePassword.isPending = false;
            state.changePassword.error = null;
            state.changePassword.success = true;
        },
        changePasswordFailure:(state,{payload})=>{
            state.changePassword.isPending = false;
            state.changePassword.error = payload;
            state.changePassword.success = false;
        },
        changePasswordResetSuccess:(state,{payload})=>{
            state.changePassword.success = null;
        }

    }
});

export const userActions = {
    authenticate: createAction("user/authenticate"),
    authenticateSuccess: createAction("user/authenticateSuccess"),
    authenticateFailure: createAction("user/authenticateFailure"),

    logout: createAction("user/logout"),
    logoutSuccess: createAction("user/logoutSuccess"),
    logoutFailure: createAction("user/logoutFailure"),


    setQuery: createAction("user/setQuery"),

    fetchUser: createAction("user/fetchUser"),
    fetchUserSuccess: createAction("user/fetchUserSuccess"),
    fetchUserFailure: createAction("user/fetchUserFailure"),

    fetchUsers: createAction("user/fetchUsers"),
    fetchUsersSuccess: createAction("user/fetchUsersSuccess"),
    fetchUsersFailure: createAction("user/fetchUsersFailure"),

    createUser: createAction("user/createUser"),
    createUserSuccess: createAction("user/createUserSuccess"),
    createUserFailure: createAction("user/createUserFailure"),

    updateUser: createAction("user/updateUser"),
    updateUserSuccess: createAction("user/updateUserSuccess"),
    updateUserFailure: createAction("user/updateUserFailure"),


    deleteUser: createAction("user/deleteUser"),
    deleteUserSuccess: createAction("user/deleteUserSuccess"),
    deleteUserFailure: createAction("user/deleteUserFailure"),

    refreshAuthToken: createAction("user/refreshAuthToken"),
    refreshAuthTokenSuccess: createAction("user/refreshAuthTokenSuccess"),
    refreshAuthTokenFailure: createAction("user/refreshAuthTokenFailure"),

    changePassword: createAction("user/changePassword"),
    changePasswordSuccess: createAction("user/changePasswordSuccess"),
    changePasswordFailure: createAction("user/changePasswordFailure"),
    changePasswordResetSuccess: createAction("user/changePasswordResetSuccess"),
}

export default slice.reducer;