import {arahubAPI} from "config/axios";
import {API_ANALYTICS_URL, API_GEMIUS_URL, API_LOCATION_URL_V2} from "config";
import {mockResolve} from "helpers";
import campaign_audience_stats from "redux/mock_response/campaign_audience_stats.json"


const getCampaigns = async (query) =>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign`,{
            params:{
                page: query?.page||1,
                size: query?.size||10,
                sort_by: query?.sort_by||"desc",
                order: query?.order||null,
                filter: query?.filter??null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getCampaignByUuid= async({uuid, is_grp=false})=>{
    try{
        if(is_grp){
            const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign/${uuid}/grp`,);
            return data;
        }

        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign/uuid/${uuid}`,);
        return data;

        // return mockResolve(true,4000,data); /** TEST MOCK */
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getCampaignStatistics=async(uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}statistics/progress/${uuid}`,);
        // return mockResolve(true,8000,data); /** TEST MOCK */
       return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getCampaignEstimates=async(uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}statistics/estimate/${uuid}`,);
        // return mockResolve(true,8000,data); /** TEST MOCK */
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const createCampaign=async(campaignData)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}campaign`,campaignData);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const updateCampaign=async(campaignData)=>{
    try{
        const {data} = await arahubAPI.put(`${API_ANALYTICS_URL}campaign/${campaignData.uuid}`,campaignData);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const deleteCampaign=async(uuid)=>{
    try{
        const {data} = await arahubAPI.delete(`${API_ANALYTICS_URL}campaign/${uuid}`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getSpotById=async({uuid,spotId})=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}campaign/${uuid}/spot/${spotId}/file`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const uploadSpotFile=async({formData,uuid, spotId})=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}campaign/${uuid}/spot/${spotId}/file`,formData);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getCitiesWithSpotsCount=async({trade_ids: industry_ids=[]})=>{
    const iIds = industry_ids.map(i=> `industry_ids=${i}`).join('&');
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL_V2}location/city/screen/number?${iIds}`,{
            params:{
                is_programmatic: true,
            },
        });

        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

/** REQUEST DO WYRZUCENIA */
const getUsedCityScreens=async(_data)=>{

    return mockResolve(true,5,{
        total_number_of_screens:0,
        number_of_screens_per_city:[]
    });
    // try{
    //     const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}screen/city/count`, {
    //         ..._data,
    //         is_programmatic:true,
    //     });
    //
    //     return data;
    // }catch (err) {
    //     return Promise.reject(err.response);
    // }
}

const getGemiusStatistics=async({timeDivision, gemius_uuid})=>{
    try{
        const {data} = await arahubAPI.get(`${API_GEMIUS_URL}stats`,{
            params:{timeDivision,campaign_id:gemius_uuid}
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getEstimatedAudience=async(_data)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}statistics/estimate`,_data);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}


// shared
const getSharedCampaignByUuid= async(uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}shared/campaign/${uuid}`,);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getSharedCampaignEstimateByUuid= async(uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}shared/statistics/estimate/${uuid}`,);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getSharedCampaignStatisticsByUuid= async(uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}shared/statistics/progress/${uuid}`,);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const shareCampaignHelper= async({campaignData, is_shared})=>{
    try{
        const {data} = await arahubAPI.put(`${API_ANALYTICS_URL}campaign/${campaignData.uuid}`, {
            // ...campaignData,
            is_shared,
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const confirmCampaign = async({_data, uuid})=>{
    try{
        try{
            const {data} = await arahubAPI.put(`${API_ANALYTICS_URL}campaign/uuid/${uuid}/confirm`,_data);
            return data;
        }catch (err) {
            return Promise.reject(err.response);
        }
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getCampaignAudienceStats = async({campaign_uuid, isFromShared = false})=>{
    try{
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}${isFromShared ? 'shared/' : ''}statistics/progress/${campaign_uuid}/age-gender`);
        return data;
        // return mockResolve(true,500,campaign_audience_stats);
    }catch (err) {
        return Promise.reject(err.response);
    }
}


const campaignService={
    getCampaigns,
    getCampaignByUuid,
    getCampaignStatistics,
    getCampaignEstimates,
    createCampaign,
    updateCampaign,
    getSpotById,
    uploadSpotFile,
    deleteCampaign,
    getCitiesWithSpotsCount,
    getUsedCityScreens,
    getGemiusStatistics,
    getEstimatedAudience,
    confirmCampaign,
    getCampaignAudienceStats,

    // shared
    getSharedCampaignByUuid,
    getSharedCampaignEstimateByUuid,
    getSharedCampaignStatisticsByUuid,
    shareCampaignHelper,
}

export default campaignService;