import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState = {
    offers:{
        isPending: false,
        error: null,
        query:{
            page: 1,
            pages:1,
            size:10,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
        offerItems:[],
    },

    offerItem:{
        isPending: false,
        error: null,
        item:{},
    },
    offerItemEstimate:{
        isPending: false,
        error: null,
        item_estimate:{},
    }
}

const slice = createSlice({
    name:'offer',
    initialState,
    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                fieldFilter,
                sort_by,
                order,
            }=payload;
            state.offers.query.size = pageSize;
            state.offers.query.page = page;
            state.offers.query.filter = filter;
            state.offers.query.fieldFilter = fieldFilter;
            state.offers.query.sort_by = sort_by;
            state.offers.query.order = order;
        },


        fetchOffers: (state,{payload})=>{
            state.offers.error = null;
            state.offers.isPending = true;
            // state.offers.offerItems = [];
        },
        fetchOffersSuccess: (state,{payload})=>{
            const{
                items,
                query:{
                    total,
                    page,
                    pages,
                    size,
                }
            }=payload;

            state.offers.query.total = total;
            state.offers.query.page = page;
            state.offers.query.pages = pages;
            state.offers.query.size = size;

            state.offers.offerItems = items;
            state.offers.error = null;
            state.offers.isPending = false;
        },
        fetchOffersFailure: (state,{payload})=>{
            state.offers.error = payload;
            state.offers.offerItems = [];
            state.offers.isPending = false;
        },


        fetchOffer: (state,{payload})=>{
            state.offerItem.error = null;
            state.offerItem.isPending = true;
        },
        fetchOfferSuccess: (state,{payload})=>{
            state.offerItem.item = payload;
            state.offerItem.error = null;
            state.offerItem.isPending = false;
        },
        fetchOfferFailure: (state,{payload})=>{
            state.offerItem.error = payload;
            state.offerItem.item = {};
            state.offerItem.isPending = true;
        },


        fetchOfferEstimate: (state,{payload})=>{
            state.offerItemEstimate.error = null;
            state.offerItemEstimate.isPending = true;
        },
        fetchOfferEstimateSuccess: (state,{payload})=>{
            state.offerItemEstimate.item_estimate = payload;
            state.offerItemEstimate.error = null;
            state.offerItemEstimate.isPending = false;
        },
        fetchOfferEstimateFailure: (state,{payload})=>{
            state.offerItemEstimate.error = payload;
            state.offerItemEstimate.item_estimate = {};
            state.offerItemEstimate.isPending = true;
        },

        deleteOffer: (state,{payload})=>{
            state.offerItem.error = null;
            state.offerItem.isPending = true;
        },
        deleteOfferSuccess: (state,{payload})=>{
            state.offerItem.error = null;
            state.offerItem.isPending = false;
        },
        deleteOfferFailure: (state,{payload})=>{
            state.offerItem.error = payload;
            state.offerItem.item = {};
            state.offerItem.isPending = true;
        },

        sendDataFromCsv: (state,{payload})=>{
            //
        },
        sendDataFromCsvSuccess: (state,{payload})=>{
            //
        },
        sendDataFromCsvFailure: (state,{payload})=>{
            //
        }
    }
});

const offerActions = {
    setQuery: createAction("offer/setQuery"),

    fetchOffers: createAction("offer/fetchOffers"),
    fetchOffersSuccess: createAction("offer/fetchOffersSuccess"),
    fetchOffersFailure: createAction("offer/fetchOffersFailure"),

    fetchOffer: createAction("offer/fetchOffer"),
    fetchOfferSuccess: createAction("offer/fetchOfferSuccess"),
    fetchOfferFailure: createAction("offer/fetchOfferFailure"),

    fetchOfferEstimate: createAction("offer/fetchOfferEstimate"),
    fetchOfferEstimateSuccess: createAction("offer/fetchOfferEstimateSuccess"),
    fetchOfferEstimateFailure: createAction("offer/fetchOfferEstimateFailure"),

    deleteOffer: createAction("offer/deleteOffer"),
    deleteOfferSuccess: createAction("offer/deleteOfferSuccess"),
    deleteOfferFailure: createAction("offer/deleteOfferFailure"),

    sendDataFromCsv: createAction("offer/sendDataFromCsv"),
    sendDataFromCsvSuccess: createAction("offer/sendDataFromCsvSuccess"),
    sendDataFromCsvFailure: createAction("offer/sendDataFromCsvFailure"),

}

export {offerActions};
export default slice.reducer;