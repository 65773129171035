import {createAction, createSlice} from "@reduxjs/toolkit"


const initialState = {
    companies:{
        isPending: false,
        error: null,
        companyItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
    },
    companyItem:{
        isPending: false,
        error: null,
        item:{},
    },
    userCompanySettings:{
        isPending: false,
        error: null,
        settings:[],
    }
}

const slice = createSlice({
    name: 'company',
    initialState,
    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                sort_by,
                order,
                fieldFilter,
            }=payload;
            state.companies.query.size = pageSize;
            state.companies.query.page = page;
            state.companies.query.filter = filter;
            state.companies.query.fieldFilter = fieldFilter;
            state.companies.query.sort_by = sort_by;
            state.companies.query.order = order;
        },


        fetchCompanies:(state,{payload})=>{
            state.companies.error = null;
            state.companies.companyItems = [];
            state.companies.isPending = true;
        },
        fetchCompaniesSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.companies.query.total = total;
            state.companies.query.page = page;
            state.companies.query.pages = pages;
            state.companies.query.size = size;

            state.companies.error = null;
            state.companies.companyItems = items;
            state.companies.isPending = false;
        },
        fetchCompaniesFailure:(state,{payload})=>{
            state.companies.error = payload;
            state.companies.companyItems = [];
            state.companies.isPending = false;
        },


        fetchCompany:(state,{payload})=>{
            state.companyItem.error = null;
            state.companyItem.item = {};
            state.companyItem.isPending = true;
        },
        fetchCompanySuccess:(state,{payload})=>{
            state.companyItem.error = null;
            state.companyItem.item = payload;
            state.companyItem.isPending = false;
        },
        fetchCompanyFailure:(state,{payload})=>{
            state.companyItem.error = payload;
            state.companyItem.item = {};
            state.companyItem.isPending = false;
        },


        createCompany:(state,{payload})=>{
            //
        },
        createCompanySuccess:(state,{payload})=>{
            //
        },
        createCompanyFailure:(state,{payload})=>{
            state.companyItem.error = payload;
        },


        updateCompany:(state,{payload})=>{
            //
        },
        updateCompanySuccess:(state,{payload})=>{
            //
        },
        updateCompanyFailure:(state,{payload})=>{
            state.companyItem.error = payload;
        },


        deleteCompany:(state,{payload})=>{
            //
        },
        deleteCompanySuccess:(state,{payload})=>{
            //
        },
        deleteCompanyFailure:(state,{payload})=>{
            state.companyItem.error = payload;
        },

        fetchUserCompanySettings:(state,{payload})=>{
            state.userCompanySettings.isPending = true;
        },
        fetchUserCompanySettingsSuccess:(state,{payload})=>{
            state.userCompanySettings.settings = payload;
            state.userCompanySettings.isPending = false;
        },
        fetchUserCompanySettingsFailure:(state,{payload})=>{
            state.userCompanySettings.error = payload;
            state.userCompanySettings.isPending = false;
        },

        updateUserCompanySettings:(state,{payload})=>{
            state.userCompanySettings.isPending = true;
        },
        updateUserCompanySettingsSuccess:(state,{payload})=>{
            state.userCompanySettings.isPending = false;
        },
        updateUserCompanySettingsFailure:(state,{payload})=>{
            // state.userCompanySettings.isPending = false;
            state.userCompanySettings.error = payload;
        },

    }
});


export const companyActions = {
    setQuery: createAction("company/setQuery"),

    fetchCompanies: createAction("company/fetchCompanies"),
    fetchCompaniesSuccess: createAction("company/fetchCompaniesSuccess"),
    fetchCompaniesFailure: createAction("company/fetchCompaniesFailure"),

    fetchCompany: createAction("company/fetchCompany"),
    fetchCompanySuccess: createAction("company/fetchCompanySuccess"),
    fetchCompanyFailure: createAction("company/fetchCompanyFailure"),

    createCompany: createAction("company/createCompany"),
    createCompanySuccess: createAction("company/createCompanySuccess"),
    createCompanyFailure: createAction("company/createCompanyFailure"),

    updateCompany: createAction("company/updateCompany"),
    updateCompanySuccess: createAction("company/updateCompanySuccess"),
    updateCompanyFailure: createAction("company/updateCompanyFailure"),

    deleteCompany: createAction("company/deleteCompany"),
    deleteCompanySuccess: createAction("company/deleteCompanySuccess"),
    deleteCompanyFailure: createAction("company/deleteCompanyFailure"),

    fetchUserCompanySettings: createAction("company/fetchUserCompanySettings"),
    fetchUserCompanySettingsSuccess: createAction("company/fetchUserCompanySettingsSuccess"),
    fetchUserCompanySettingsFailure: createAction("company/fetchUserCompanySettingsFailure"),

    updateUserCompanySettings: createAction("company/updateUserCompanySettings"),
    updateUserCompanySettingsSuccess: createAction("company/updateUserCompanySettingsSuccess"),
    updateUserCompanySettingsFailure: createAction("company/updateUserCompanySettingsFailure"),
}

export default slice.reducer;