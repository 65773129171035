import {createAction, createSlice, current} from "@reduxjs/toolkit";
import {roundOffTo, sessionStorageHelper} from "helpers";

const initialState = {

    cities:{
        isPending: false,
        error: null,
        cityItems:[],
        query:{
            // jeszcze nie wspierane
        },
    },

    locationsDevices:{
        isPending: false,
        pendingStatus:'STANDBY', // 'STANDBY','PENDING','PENDING_DONE', 'PENDING_FAILURE',
        error: null,
        items:[],
        query:{
            page:1,
            pages:1,
            size:25,
            total:1,

            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        }
    },

    locationsDevicesStats:{
        isPending: false,
        totalIsPending: false,
        error: null,
        items:[],
        totalAudience:0,
        totalDevices:0,
        totalScreens:0,
    },

    locationDevicesPlot:{
        isPending: false,
        error: null,
        items:[],
    },

    locationDevicesPeopleNetGrossPlot:{
        isPending: false,
        error: null,
        items:[],
    },

    locationDevicesDayToDayPlot:{
        isPending: false,
        error: null,
        items:[],
        helper:{
            max_val:0,
        }
    },

    itemLocationDevicePlot:{
        // prawie to samo co locationDevicesPlot
        // z tym, że per 1 lokalizacja
        isPending: false,
        error: null,
        item:[],
        totals:{
            total_people_gross:0,
            total_people_net:0,
        },
    },

    // age_gener plot
    locationDevicesAgeGenderPlot:{ // dniówki wybrany zakres
        isPending: false,
        error: null,
        items:[],
        total:{}, // suma
    },
    locationDevicesAgeGenderPlotHourly:{ // godzinówki - ostatnie 24h
        isPending: false,
        error: null,
        items:[],
        total:{}, // suma
    },
    locationDevicesAgeGenderPlotRangeHourly:{ // godzinówki wybrany zakres - do statystyk dzień noc
        isPending: false,
        error: null,
        items:[],
        total:{}, // suma
    },


    itemLocationView:{
        isPending: false,
        error: null,
        pictureLink:null,
        params:null,
    },

    locationReport:{
        isPending: false,
        error: null,
        reportFile:null,
    }
}

const slice = createSlice({
    name: 'youraraboxes',
    initialState,
    reducers:{

        setQuery:(state,{payload})=>{
            const{
                page,
                pageSize,
                filter,
                fieldFilter,
            }=payload;
            state.locationsDevices.query.page = page;
            state.locationsDevices.query.size = pageSize;
            state.locationsDevices.query.filter = filter;
            state.locationsDevices.query.fieldFilter = fieldFilter;
        },

        /** FETCH CITIES */
        fetchCities: (state,{payload})=>{
            state.cities.cityItems = [];
            state.cities.isPending = true;
            state.cities.error = null;
        },
        fetchCitiesSuccess: (state,{payload})=>{
            state.cities.isPending = false;
            state.cities.cityItems = payload;
        },
        fetchCitiesFailure: (state,{payload})=>{
            state.cities.isPending = false;
            state.cities.error = payload;
        },

        /** CHECKBOX CITY HELPER */
         citiesHelper:(state,{payload})=>{
            switch(payload.type){
                case 'TOGGLE_CHECK_ITEM':{
                    const _ind = state.cities.cityItems.findIndex(i=> i.id === payload.item.id);
                    state.cities.cityItems[_ind].isChecked = !state.cities.cityItems[_ind].isChecked;

                    // memorize approach SET
                    if(payload?.isAdmin){
                        sessionStorageHelper(
                            {type:'SET',key:'your-araboxes-overview-agglomerations',value:current(state.cities.cityItems)}
                        );
                    }
                    break;
                }
                case 'TOGGLE_CHECK_ITEMS_INIT':{
                    // memorize approach
                    state.cities.cityItems = payload.items;
                    break;
                }
                case 'CHECK_ALL':{
                    state.cities.cityItems = state.cities.cityItems.map(i=> ({...i, isChecked: i.__isVisible}));
                    break;
                }
                case 'UNCHECK_ALL':{
                    state.cities.cityItems = state.cities.cityItems.map(i=> ({...i, isChecked:false}));
                    break;
                }
                default: break;
            }
         },

        /** FETCH LOCATIONS DEVICES todo -> add query */
        fetchLocationsDevices:(state,{payload})=>{
            state.locationsDevices.isPending = true;
            state.locationsDevices.pendingStatus = 'PENDING';
            state.locationsDevices.error = null;
        },
        fetchLocationsDevicesSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            const _user = JSON.parse(localStorage.getItem("user"));
            if(_user?.role_id ===1){
                state.locationsDevices.items = items.map(i => ({...i, audience: "pending"}));
            }else{
                state.locationsDevices.items = items.reduce((acc,item)=>{
                    // if(item?.screen_name?.length>0){
                    //     if(item?.screen_name?.toLocaleLowerCase()?.includes("test")){
                    //         //
                    //     }else if(item?.location_status === 'active'){
                    //         acc.push({...item, audience: "pending"});
                    //     }
                    // }
                    if(item?.location_status === 'active'){
                        acc.push({...item, audience: "pending"});
                    }
                    return acc;
                },[]);
            }

            // old
            // state.locationsDevices.items = items.map(i => ({...i, audience: "pending"}));

            state.locationsDevices.query.total = total;
            state.locationsDevices.query.page = page;
            state.locationsDevices.query.pages = pages;
            state.locationsDevices.query.size = size;
            state.locationsDevices.isPending = false;
            state.locationsDevices.pendingStatus = 'PENDING_DONE';
        },
        fetchLocationsDevicesFailure:(state,{payload})=>{
            state.locationsDevices.isPending = false;
            state.locationsDevices.error = payload;
            state.locationsDevices.pendingStatus = 'PENDING_FAILURE';
        },



        fetchLocationsDevicesStats:(state,{payload})=>{
            state.locationsDevicesStats.isPending = true;
            state.locationsDevicesStats.error = null;
            state.locationsDevices.pendingStatus = 'STANDBY';
        },
        fetchLocationsDevicesStatsSuccess:(state,{payload})=>{

            state.locationsDevicesStats.isPending = false;
            state.locationsDevicesStats.items = payload;

            // test
            state.locationsDevices.items = current(state.locationsDevices.items).reduce((acc,item)=>{
                acc.push({
                    ...item,
                    audience: payload?.device_stats?.find(i=> item.location_uuid === i.location_uuid)?.audience??0,
                });
                return acc;
            },[]);
        },
        fetchLocationsDevicesStatsFailure:(state,{payload})=>{
            state.locationsDevicesStats.isPending = false;
            state.locationsDevicesStats.error = payload;
        },



        fetchLocationsDevicesStatsTotal:(state,{payload})=>{
            state.locationsDevicesStats.totalIsPending = true;
        },
        fetchLocationsDevicesStatsTotalSuccess:(state,{payload})=>{
            state.locationsDevicesStats.totalIsPending = false;
            state.locationsDevicesStats.totalAudience = payload?.total_device_audience||0;
            state.locationsDevicesStats.totalDevices = payload?.total_devices||0;
            state.locationsDevicesStats.totalScreens = payload?.total_screens||0;
        },
        fetchLocationsDevicesStatsTotalFailure:(state,{payload})=>{
            state.locationsDevicesStats.totalIsPending = false;
            state.locationsDevicesStats.error = payload;
        },




        fetchLocationDevicesPlot:(state,{payload})=>{
            state.locationDevicesPlot.error = null;
            state.locationDevicesPlot.isPending = true;
        },
        fetchLocationDevicesPlotSuccess:(state,{payload})=>{
            state.locationDevicesPlot.items = payload?.device_audience??[];
            state.locationDevicesPlot.isPending = false;
        },
        fetchLocationDevicesPlotFailure:(state,{payload})=>{
            state.locationDevicesPlot.error = payload;
            state.locationDevicesPlot.isPending = false;
        },


        fetchLocationDevicesPeopleNetGrosPlot:(state,{payload})=>{
            state.locationDevicesPeopleNetGrossPlot.error = null;
            state.locationDevicesPeopleNetGrossPlot.isPending = true;
        },
        fetchLocationDevicesPeopleNetGrosPlotSuccess:(state,{payload})=>{
            state.locationDevicesPeopleNetGrossPlot.items = payload?.net_gross_plot_stats??[];
            state.locationDevicesPeopleNetGrossPlot.isPending = false;
        },
        fetchLocationDevicesPeopleNetGrosPlotFailure:(state,{payload})=>{
            state.locationDevicesPeopleNetGrossPlot.error = payload;
            state.locationDevicesPeopleNetGrossPlot.isPending = false;
        },

        fetchLocationDevicesDayToDayPlot:(state,{payload})=>{
            state.locationDevicesDayToDayPlot.error = null;
            state.locationDevicesDayToDayPlot.isPending = true;
        },
        fetchLocationDevicesDayToDayPlotSuccess:(state,{payload})=>{

            state.locationDevicesDayToDayPlot.items = payload?.device_weekday_stats??[];

            if(payload?.device_weekday_stats?.length>0){
                const reduced = payload?.device_weekday_stats.reduce((acc,item)=>{
                    if(item?.net_gross_stats?.length>0){
                        const _tempMax = Math.max(...item?.net_gross_stats.map(x => Math.max(x?.people_gross??0 , x?.people_net??0)));
                        acc = acc < _tempMax ? _tempMax : acc;
                    }
                    return acc;
                },0);
                state.locationDevicesDayToDayPlot.helper.max_val = roundOffTo(reduced??0,1000);
            }
            state.locationDevicesDayToDayPlot.isPending = false;
        },
        fetchLocationDevicesDayToDayPlotFailure:(state,{payload})=>{
            state.locationDevicesDayToDayPlot.error = payload;
            state.locationDevicesDayToDayPlot.isPending = false;
        },

        fetchItemLocationDevicePlot:(state,{payload})=>{
            state.itemLocationDevicePlot.error = null;
            state.itemLocationDevicePlot.isPending = true;
        },
        fetchItemLocationDevicePlotSuccess:(state,{payload})=>{

            state.itemLocationDevicePlot.item = payload?.device_audience??[];

            if(payload?.device_audience?.length>0){
                const temp = payload.device_audience.reduce((acc,item)=>{
                    acc.total_people_gross += item.people;
                    acc.total_people_net += item.people_net;
                    return acc;
                },{
                    total_people_gross:0,
                    total_people_net:0,
                });
                state.itemLocationDevicePlot.totals.total_people_gross = temp.total_people_gross;
                state.itemLocationDevicePlot.totals.total_people_net = temp.total_people_net;
            }else{
                state.itemLocationDevicePlot.totals.total_people_gross = 0;
                state.itemLocationDevicePlot.totals.total_people_net = 0;
            }

            state.itemLocationDevicePlot.isPending = false;
        },
        fetchItemLocationDevicePlotFailure:(state,{payload})=>{
            state.itemLocationDevicePlot.error = payload;
            state.itemLocationDevicePlot.isPending = false;
        },


        // age-gener plot
        fetchLocationDevicesAgeGenderPlot:(state,{payload})=>{
            if(payload.aggregation === 'DAY'){
                state.locationDevicesAgeGenderPlot.error = null;
                state.locationDevicesAgeGenderPlot.isPending = true;
            }
            else if(payload.aggregation === 'HOUR'){
                state.locationDevicesAgeGenderPlotHourly.error = null;
                state.locationDevicesAgeGenderPlotHourly.isPending = true;
            }

            // RANGE_HOUR - godzinówki dla zakresu
            else if(payload.aggregation === 'RANGE_HOUR'){
                state.locationDevicesAgeGenderPlotRangeHourly.error = null;
                state.locationDevicesAgeGenderPlotRangeHourly.isPending = true;
            }
        },
        fetchLocationDevicesAgeGenderPlotSuccess:(state,{payload})=>{
            if(payload.aggregation === 'DAY'){
                state.locationDevicesAgeGenderPlot.items = payload?.items??[];
                state.locationDevicesAgeGenderPlot.total = {...payload, items:null};
                state.locationDevicesAgeGenderPlot.isPending = false;
            }
            else if(payload.aggregation === 'HOUR'){
                state.locationDevicesAgeGenderPlotHourly.items = payload?.items??[];
                state.locationDevicesAgeGenderPlotHourly.total = {...payload, items:null};
                state.locationDevicesAgeGenderPlotHourly.isPending = false;
            }

            // RANGE_HOUR - godzinówki dla zakresu
            else if(payload.aggregation === 'RANGE_HOUR'){
                console.log("tutaj",payload.aggregation)
                state.locationDevicesAgeGenderPlotRangeHourly.items = payload?.items??[];
                state.locationDevicesAgeGenderPlotRangeHourly.total = {...payload, items:null};
                state.locationDevicesAgeGenderPlotRangeHourly.isPending = false;
            }
        },
        fetchLocationDevicesAgeGenderPlotFailure:(state,{payload})=>{
            if(payload.aggregation === 'DAY'){
                state.locationDevicesAgeGenderPlot.error = payload;
                state.locationDevicesAgeGenderPlot.isPending = false;
            }
            else if(payload.aggregation === 'HOUR'){
                state.locationDevicesAgeGenderPlotHourly.error = payload;
                state.locationDevicesAgeGenderPlotHourly.isPending = false;
            }

            // RANGE_HOUR - godzinówki dla zakresu
            else if(payload.aggregation === 'RANGE_HOUR'){
                state.locationDevicesAgeGenderPlotRangeHourly.error = payload;
                state.locationDevicesAgeGenderPlotRangeHourly.isPending = false;
            }
        },



        fetchAraboxViewImage:(state,{payload})=>{
            state.itemLocationView.isPending = true;
        },
        fetchAraboxViewImageSuccess:(state,{payload})=>{
            state.itemLocationView.pictureLink = payload?.panorama??null;
            state.itemLocationView.params = payload?.params;
            state.itemLocationView.isPending = false;
        },
        fetchAraboxViewImageFailure:(state,{payload})=>{
            state.itemLocationView.error = payload;
            state.itemLocationView.isPending = false;
        },


        fetchLocationReport:(state,{payload})=>{
            state.locationReport.reportFile = null;
            state.locationReport.isPending = true;
        },
        fetchLocationReportSuccess:(state,{payload})=>{
            state.locationReport.reportFile = payload;
            state.locationReport.isPending = false;
        },
        fetchLocationReportFailure:(state,{payload})=>{
            state.locationReport.reportFile = null;
            state.locationReport.error = payload;
            state.locationReport.isPending = false;
        }
    }
});

const yourAraboxesActions={

    setQuery: createAction("youraraboxes/setQuery"),

    fetchCities: createAction("youraraboxes/fetchCities"),
    fetchCitiesSuccess: createAction("youraraboxes/fetchCitiesSuccess"),
    fetchCitiesFailure: createAction("youraraboxes/fetchCitiesFailure"),

    citiesHelper: createAction("youraraboxes/citiesHelper"),

    fetchLocationsDevices: createAction("youraraboxes/fetchLocationsDevices"),
    fetchLocationsDevicesSuccess: createAction("youraraboxes/fetchLocationsDevicesSuccess"),
    fetchLocationsDevicesFailure: createAction("youraraboxes/fetchLocationsDevicesFailure"),

    fetchLocationsDevicesStats: createAction("youraraboxes/fetchLocationsDevicesStats"),
    fetchLocationsDevicesStatsSuccess: createAction("youraraboxes/fetchLocationsDevicesStatsSuccess"),
    fetchLocationsDevicesStatsFailure: createAction("youraraboxes/fetchLocationsDevicesStatsFailure"),

    fetchLocationsDevicesStatsTotal: createAction("youraraboxes/fetchLocationsDevicesStatsTotal"),
    fetchLocationsDevicesStatsTotalSuccess: createAction("youraraboxes/fetchLocationsDevicesStatsTotalSuccess"),
    fetchLocationsDevicesStatsTotalFailure: createAction("youraraboxes/fetchLocationsDevicesStatsTotalFailure"),

    fetchLocationDevicesPlot: createAction("youraraboxes/fetchLocationDevicesPlot"),
    fetchLocationDevicesPlotSuccess: createAction("youraraboxes/fetchLocationDevicesPlotSuccess"),
    fetchLocationDevicesPlotFailure: createAction("youraraboxes/fetchLocationDevicesPlotFailure"),

    fetchLocationDevicesPeopleNetGrosPlot: createAction("youraraboxes/fetchLocationDevicesPeopleNetGrosPlot"),
    fetchLocationDevicesPeopleNetGrosPlotSuccess: createAction("youraraboxes/fetchLocationDevicesPeopleNetGrosPlotSuccess"),
    fetchLocationDevicesPeopleNetGrosPlotFailure: createAction("youraraboxes/fetchLocationDevicesPeopleNetGrosPlotFailure"),

    fetchLocationDevicesDayToDayPlot: createAction("youraraboxes/fetchLocationDevicesDayToDayPlot"),
    fetchLocationDevicesDayToDayPlotSuccess: createAction("youraraboxes/fetchLocationDevicesDayToDayPlotSuccess"),
    fetchLocationDevicesDayToDayPlotFailure: createAction("youraraboxes/fetchLocationDevicesDayToDayPlotFailure"),

    fetchItemLocationDevicePlot: createAction("youraraboxes/fetchItemLocationDevicePlot"),
    fetchItemLocationDevicePlotSuccess: createAction("youraraboxes/fetchItemLocationDevicePlotSuccess"),
    fetchItemLocationDevicePlotFailure: createAction("youraraboxes/fetchItemLocationDevicePlotFailure"),

    fetchLocationDevicesAgeGenderPlot: createAction("youraraboxes/fetchLocationDevicesAgeGenderPlot"),
    fetchLocationDevicesAgeGenderPlotSuccess: createAction("youraraboxes/fetchLocationDevicesAgeGenderPlotSuccess"),
    fetchLocationDevicesAgeGenderPlotFailure: createAction("youraraboxes/fetchLocationDevicesAgeGenderPlotFailure"),

    fetchAraboxViewImage: createAction("youraraboxes/fetchAraboxViewImage"),
    fetchAraboxViewImageSuccess: createAction("youraraboxes/fetchAraboxViewImageSuccess"),
    fetchAraboxViewImageFailure: createAction("youraraboxes/fetchAraboxViewImageFailure"),

    fetchLocationReport: createAction("youraraboxes/fetchLocationReport"),
    fetchLocationReportSuccess: createAction("youraraboxes/fetchLocationReportSuccess"),
    fetchLocationReportFailure: createAction("youraraboxes/fetchLocationReportFailure"),
}

export {yourAraboxesActions};

export default slice.reducer;