import axios from "axios";
import Cookies from 'universal-cookie';
import {API_USERS_URL} from "config";
import {userActions} from "redux/resources/user/data";
import {store} from "redux/root/Store";
import jwtDecode from "jwt-decode";
import i18n from "i18next";
import {cToast} from "helpers";

const cookies = new Cookies();

export const arahubAPI = axios.create({
    // withCredentials: true,
    // headers: {"Content-Type": "application/json", Accept: 'application/json'}
});

// => Request interceptor
arahubAPI.interceptors.request.use(async (config)=>{
    const accessToken = await cookies.get("access_token");
    const refreshToken = cookies.get("refresh_token");
    if(accessToken){
        if(accessToken && jwtDecode(accessToken).exp < Date.now()/1000){
            await axios.post(`${API_USERS_URL}auth/refresh-token`,{'refresh_token':refreshToken}).then(async({data})=>{
                if(data.access_token){
                   await cookies.set('access_token',data.access_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
                    config.headers.authorization = `Bearer ${data.access_token}`;
                }
            }).catch(()=>{
                store.dispatch(userActions.logout());
                cToast({msg: i18n.t("Session was expired"),variant:'error',toastId: 1});
            });
        }else{
            config.headers.authorization = `Bearer ${accessToken}`;
        }
    }
    return config;
},(err)=>{
    return Promise.reject(err);
});



// => Response interceptor
arahubAPI.interceptors.response.use( (res)=>{
    return res;
},async (err)=> {
        const originalRequest = err.config;
        if ((err.response.status === 401 || err.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = await cookies.get("refresh_token");
            await axios.post(`${API_USERS_URL}auth/refresh-token`,{'refresh_token':refreshToken}).then(({data})=>{
                if(data.access_token){
                cookies.set('access_token',data.access_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
                axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
                }
            }).catch(()=>{
                store.dispatch(userActions.logout());
            });
            return arahubAPI(originalRequest);
        }
        return Promise.reject(err);
});
