import {arahubAPI} from "config/axios";
import {API_ANALYTICS_URL, API_LOCATION_URL, API_PLATFORMA_URL} from "config";
import {mockResolve} from "helpers";

const SOURCE = "arabox";


// arabox_old_uuid
const getLiveAraboxTraffic=async(araboxId)=>{
    try{
        const {data} = await arahubAPI.get(`${API_PLATFORMA_URL}${SOURCE}/traffic/avg/${araboxId}`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

// arabox_old_uuid
const getTodayAraboxRawData=async(araboxId)=>{
    try{
        const {data} = await arahubAPI.get(`${API_PLATFORMA_URL}${SOURCE}/stats/${araboxId}/hourly`);
        return data;
    }catch (err) {
        return Promise.reject(err?.response);
    }
}

const getAraboxZones=async(location_uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}location/${location_uuid}/zones`);
        return data;
    }catch (err) {
        return Promise.reject(err?.response);
    }
}

const getAraboxHeatmap=async({uuid,page=1})=>{
    try {
        const {data} = await arahubAPI.get(`${API_ANALYTICS_URL}heatmap/${uuid}`,{
            params:{
                page,
            }
        });
        return data;
    }catch (err) {
        return Promise.reject(err?.response);
    }
}

const araboxLiveService={
    getLiveAraboxTraffic,
    getTodayAraboxRawData,
    getAraboxZones,
    getAraboxHeatmap,
}

export default araboxLiveService;