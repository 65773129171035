import {createAction, createSlice} from "@reduxjs/toolkit";


const initialState = {
    liveTraffic:{
        isPending: false,
        error: null,
        item:{
            time:0,
            quality:0,
            net:0,
            gross:0,
            car:0,
            carPeople:0,
            bus:0,
            busPeople:0,
            avg:0,
        },
    },
    todayHourlyStats:{
        isPending: false,
        error: null,
        items:[],
    },
    liveZones:{
        top:0,
        left:0,
        middle:0,
        right:0,
        total:0,
    },

    screenPosition:{ // STATIC for now
        x1: 250,
        y1: 495,
        x2: 750,
        y2: 495,
        enabled: false,
        btn: null
    },

    araboxZones:{
        isPending: false,
        error: null,
        zonesPosition:[],
    },

    araboxHeatmap:{
        isPending: false,
        error: null,
        paths:[],
        has_more: null,
        currentPage:1,
    }
}

const slice = createSlice({
    name: 'araboxlive',
    initialState,
    reducers:{

        fetchLiveTraffic: (state,{payload})=>{
            state.liveTraffic.isPending = true;
        },
        fetchLiveTrafficSuccess: (state,{payload})=>{
            state.liveTraffic.item = payload;
            state.liveTraffic.isPending = false;
        },
        fetchLiveTrafficFailure: (state,{payload})=>{
            state.liveTraffic.error = payload;
            state.liveTraffic.isPending = false;
        },


        fetchTodayHourlyStats: (state,{payload})=>{
            state.todayHourlyStats.isPending = true;
        },
        fetchTodayHourlyStatsSuccess: (state,{payload})=>{
            state.todayHourlyStats.items = payload?.items||[];
            state.todayHourlyStats.isPending = false;
        },
        fetchTodayHourlyStatsFailure: (state,{payload})=>{
            state.todayHourlyStats.error = payload;
            state.todayHourlyStats.isPending = false;
        },


        fetchAraboxZones: (state,{payload})=>{
            state.araboxZones.isPending = true;
        },
        fetchAraboxZonesSuccess: (state,{payload})=>{
            state.araboxZones.zonesPosition = payload?.[0]?.file_data ? JSON.parse(payload?.[0]?.file_data) : [];
            state.araboxZones.isPending = false;
        },
        fetchAraboxZonesFailure: (state,{payload})=>{
            state.araboxZones.error = payload;
            state.araboxZones.isPending = false;
        },

        fetchAraboxHeatmap: (state,{payload})=>{
            if(payload?.isFirstBump){
                state.araboxHeatmap.paths =[];
                state.araboxHeatmap.currentPage=1;
            }else{
                state.araboxHeatmap.currentPage +=1;
            }
            state.araboxHeatmap.isPending = true;
        },
        fetchAraboxHeatmapSuccess: (state,{payload})=>{
            if(payload?.paths?.length>0){
                state.araboxHeatmap.paths.push(...payload?.paths);
            }
            state.araboxHeatmap.has_more = payload?.has_more??null;
            state.araboxHeatmap.isPending = false;
        },
        fetchAraboxHeatmapFailure: (state,{payload})=>{
            state.araboxHeatmap.error = payload;
            state.araboxHeatmap.paths=[];
            state.araboxHeatmap.isPending = false;
        },

        socketUpdateHelper: (state,{payload})=>{
                if(payload?.type === "realtime"){
                    // state.liveZones.top = (payload.top + payload["top-leave"]);
                    // state.liveZones.left = (payload.left + payload["left-leave"]);
                    // state.liveZones.middle = (payload.middle + payload["middle-leave"]);
                    // state.liveZones.right = (payload.right + payload["right-leave"]);
                    state.liveZones.top = payload.top;
                    state.liveZones.left = payload.left;
                    state.liveZones.middle = payload.middle;
                    state.liveZones.right = payload.right;
                    state.liveZones.total = (payload.top + payload.left + payload.middle + payload.right);
                }else if(payload?.type === "path"){
                    switch (payload?.object){
                        case "person":{
                            if(payload?.net){
                                // += person_net
                                state.liveTraffic.item.net += 1;
                            }
                            if(payload?.gross){
                                // += person_gross
                                state.liveTraffic.item.quantity += 1;
                            }
                            break;
                        }
                        case "car":{
                            if(payload?.net){
                                // += car_net
                                state.liveTraffic.item.carPeople += 1;  // todo -> ???????
                            }
                            if(payload?.gross){
                                // += car_gross
                                state.liveTraffic.item.car += 1;
                            }
                            break;
                        }
                        case "bus":{
                            if(payload?.net){
                                // += bus_net
                                state.liveTraffic.item.busPeople += 1;  // todo -> ???????
                            }
                            if(payload?.gross){
                                // += bus_gross
                                state.liveTraffic.item.bus += 1;
                            }
                            break;
                        }
                        default: break;
                    }
                }
        }
    }
});

const araboxLiveActions ={
    fetchLiveTraffic: createAction("araboxlive/fetchLiveTraffic"),
    fetchLiveTrafficSuccess: createAction("araboxlive/fetchLiveTrafficSuccess"),
    fetchLiveTrafficFailure: createAction("araboxlive/fetchLiveTrafficFailure"),

    fetchTodayHourlyStats: createAction("araboxlive/fetchTodayHourlyStats"),
    fetchTodayHourlyStatsSuccess: createAction("araboxlive/fetchTodayHourlyStatsSuccess"),
    fetchTodayHourlyStatsFailure: createAction("araboxlive/fetchTodayHourlyStatsFailure"),

    fetchAraboxZones: createAction("araboxlive/fetchAraboxZones"),
    fetchAraboxZonesSuccess: createAction("araboxlive/fetchAraboxZonesSuccess"),
    fetchAraboxZonesFailure: createAction("araboxlive/fetchAraboxZonesFailure"),

    fetchAraboxHeatmap: createAction("araboxlive/fetchAraboxHeatmap"),
    fetchAraboxHeatmapSuccess: createAction("araboxlive/fetchAraboxHeatmapSuccess"),
    fetchAraboxHeatmapFailure: createAction("araboxlive/fetchAraboxHeatmapFailure"),

    socketUpdateHelper: createAction("araboxlive/socketUpdateHelper"),
}

export {araboxLiveActions};

export default slice.reducer;