import {createAction, createSlice} from "@reduxjs/toolkit";


// const RESOURCE_NAME = 'device';
// const P_RESOURCE_NAME = `${RESOURCE_NAME}s`;

const initialState = {
    devices:{
        isPending: false,
        error: null,
        deviceItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
    },
    deviceItem:{
        isPending: false,
        error: null,
        item:{},
    }
}


const slice = createSlice({
    name: 'device',
    initialState,

    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                fieldFilter,
                sort_by,
                order,
            }=payload;
            state.devices.query.size = pageSize;
            state.devices.query.page = page;
            state.devices.query.filter = filter;
            state.devices.query.fieldFilter = fieldFilter;
            state.devices.query.sort_by = sort_by;
            state.devices.query.order = order;
        },


        fetchDevices:(state,{payload})=>{
            state.devices.error = null;
            state.devices.deviceItems = [];
            state.devices.isPending = true;
        },
        fetchDevicesSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.devices.query.total = total;
            state.devices.query.page = page;
            state.devices.query.pages = pages;
            state.devices.query.size = size;

            state.devices.error = null;
            state.devices.deviceItems = items;
            state.devices.isPending = false;
        },
        fetchDevicesFailure:(state,{payload})=>{
            state.devices.error = payload;
            state.devices.deviceItems = [];
            state.devices.isPending = false;
        },


        fetchDevice:(state,{payload})=>{
            state.deviceItem.error = null;
            state.deviceItem.item = {};
            state.deviceItem.isPending = true;
        },
        fetchDeviceSuccess:(state,{payload})=>{
            state.deviceItem.error = null;
            state.deviceItem.item = payload;
            state.deviceItem.isPending = false;
        },
        fetchDeviceFailure:(state,{payload})=>{
            state.deviceItem.error = payload;
            state.deviceItem.item = {};
            state.deviceItem.isPending = false;
        },

        createDevice:(state,{payload})=>{
            //
        },
        createDeviceSuccess:(state,{payload})=>{
            //
        },
        createDeviceFailure:(state,{payload})=>{
            state.deviceItem.error = payload;
        },


        updateDevice:(state,{payload})=>{
            //
        },
        updateDeviceSuccess:(state,{payload})=>{
            //
        },
        updateDeviceFailure:(state,{payload})=>{
            state.deviceItem.error = payload;
        },


        deleteDevice:(state,{payload})=>{
            //
        },
        deleteDeviceSuccess:(state,{payload})=>{
            //
        },
        deleteDeviceFailure:(state,{payload})=>{
            state.deviceItem.error = payload;
        }

    }
});


export const deviceActions = {
    setQuery: createAction("device/setQuery"),

    fetchDevices: createAction("device/fetchDevices"),
    fetchDevicesSuccess: createAction("device/fetchDevicesSuccess"),
    fetchDevicesFailure: createAction("device/fetchDevicesFailure"),

    fetchDevice: createAction("device/fetchDevice"),
    fetchDeviceSuccess: createAction("device/fetchDeviceSuccess"),
    fetchDeviceFailure: createAction("device/fetchDeviceFailure"),

    createDevice: createAction("device/createDevice"),
    createDeviceSuccess: createAction("device/createDeviceSuccess"),
    createDeviceFailure: createAction("device/createDeviceFailure"),

    updateDevice: createAction("device/updateDevice"),
    updateDeviceSuccess: createAction("device/updateDeviceSuccess"),
    updateDeviceFailure: createAction("device/updateDeviceFailure"),

    deleteDevice: createAction("device/deleteDevice"),
    deleteDeviceSuccess: createAction("device/deleteDeviceSuccess"),
    deleteDeviceFailure: createAction("device/deleteDeviceFailure"),
}

export default slice.reducer;