import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import deviceService from "./api";
import {deviceActions} from "./data";

function* fetchDevices({payload}){
    try{
        const data = yield call(deviceService.getDevices,payload);
        yield put(deviceActions.fetchDevicesSuccess(data));
    }catch (err) {
        yield put(deviceActions.fetchDevicesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


export default function* deviceSaga(){
    yield takeLatest(deviceActions.fetchDevices.type, fetchDevices);
}