import {createAction, createSlice} from "@reduxjs/toolkit"


const initialState = {
    roles:{
        isPending: false,
        error: null,
        roleItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: 'created_at',
            order:'desc'
        },
    },
    roleItem:{
        isPending: false,
        error: null,
        item:{},
    }
}

const slice = createSlice({
    name: 'role',
    initialState,
    reducers:{

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
            }=payload;
            state.roles.query.size = pageSize;
            state.roles.query.page = page;
        },


        fetchRoles:(state,{payload})=>{
            state.roles.error = null;
            state.roles.roleItems = [];
            state.roles.isPending = true;
        },
        fetchRolesSuccess:(state,{payload})=>{
            const{
                items,
                total,
                page,
                pages,
                size,
            }=payload;

            state.roles.query.total = total;
            state.roles.query.page = page;
            state.roles.query.pages = pages;
            state.roles.query.size = size;

            state.roles.error = null;
            state.roles.roleItems = items;
            state.roles.isPending = false;
        },
        fetchRolesFailure:(state,{payload})=>{
            state.roles.error = payload;
            state.roles.roleItems = [];
            state.roles.isPending = false;
        },


        fetchRole:(state,{payload})=>{
            state.roleItem.error = null;
            state.roleItem.item = {};
            state.roleItem.isPending = true;
        },
        fetchRoleSuccess:(state,{payload})=>{
            state.roleItem.error = null;
            state.roleItem.item = payload;
            state.roleItem.isPending = false;
        },
        fetchRoleFailure:(state,{payload})=>{
            state.roleItem.error = payload;
            state.roleItem.item = {};
            state.roleItem.isPending = false;
        },


        createRole:(state,{payload})=>{
            //
        },
        createRoleSuccess:(state,{payload})=>{
            //
        },
        createRoleFailure:(state,{payload})=>{
            state.roleItem.error = payload;
        },


        updateRole:(state,{payload})=>{
            //
        },
        updateRoleSuccess:(state,{payload})=>{
            //
        },
        updateRoleFailure:(state,{payload})=>{
            state.roleItem.error = payload;
        },


        deleteRole:(state,{payload})=>{
            //
        },
        deleteRoleSuccess:(state,{payload})=>{
            //
        },
        deleteRoleFailure:(state,{payload})=>{
            state.roleItem.error = payload;
        }

    }
});


export const roleActions = {
    setQuery: createAction("role/setQuery"),

    fetchRoles: createAction("role/fetchRoles"),
    fetchRolesSuccess: createAction("role/fetchRolesSuccess"),
    fetchRolesFailure: createAction("role/fetchRolesFailure"),

    fetchRole: createAction("role/fetchRole"),
    fetchRoleSuccess: createAction("role/fetchRoleSuccess"),
    fetchRoleFailure: createAction("role/fetchRoleFailure"),

    createRole: createAction("role/createRole"),
    createRoleSuccess: createAction("role/createRoleSuccess"),
    createRoleFailure: createAction("role/createRoleFailure"),

    updateRole: createAction("role/updateRole"),
    updateRoleSuccess: createAction("role/updateRoleSuccess"),
    updateRoleFailure: createAction("role/updateRoleFailure"),

    deleteRole: createAction("role/deleteRole"),
    deleteRoleSuccess: createAction("role/deleteRoleSuccess"),
    deleteRoleFailure: createAction("role/deleteRoleFailure"),
}

export default slice.reducer;