import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import MassiveActions from "../views/industry/MassiveActions";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Testing = Loadable(lazy(() => import('../views/testing/Testing')));

/* Campaign */
const Campaign = Loadable(lazy(() => import('../views/campaign/Campaign')));
const CampaignList = Loadable(lazy(() => import('../views/campaign/CampaignList')));
const CampaignPreview = Loadable(lazy(() => import('../views/campaign/CampaignPreview')));
const CampaignForm = Loadable(lazy(() => import('../views/campaign/campaignForm/CampaignForm')));



/* Offer */
const Offer = Loadable(lazy(() => import('../views/offer/OfferMain')));
const OfferList = Loadable(lazy(() => import('../views/offer/OfferList')));
const OfferPreview = Loadable(lazy(() => import('../views/offer/OfferPreview')));
const CampaignImport = Loadable(lazy(() => import('../views/campaign/CampaignImport')));

/* Profile */
const Profile = Loadable(lazy(() => import('../views/profile/profileMain')));

/* Shared */
const SharedCampaign = Loadable(lazy(() => import('../views/shared/campaign')));

/* Users */
const User = Loadable(lazy(() => import('../views/user/UserMain')));
const UsersList = Loadable(lazy(() => import('../views/user/UsersList')));
const UserForm = Loadable(lazy(() => import('../views/user/UserForm')));

/* Role */
const Role = Loadable(lazy(() => import('../views/role/RoleMain')));
const RolesList = Loadable(lazy(() => import('../views/role/RolesList')));
const RoleForm = Loadable(lazy(() => import('../views/role/RoleForm')));

/* Company */
const Company = Loadable(lazy(() => import('../views/company/CompanyMain')));
const CompaniesList = Loadable(lazy(() => import('../views/company/CompaniesList')));
const CompanyForm = Loadable(lazy(() => import('../views/company/CompanyForm')));

/* Location */
const Location = Loadable(lazy(() => import('../views/location/LocationMain')));
const LocationsList = Loadable(lazy(() => import('../views/location/LocationsList')));
const LocationForm = Loadable(lazy(() => import('../views/location/LocationForm')));

/* City */
const City = Loadable(lazy(() => import('../views/city/CityMain')));
const CitiesList = Loadable(lazy(() => import('../views/city/CitiesList')));
const CityForm = Loadable(lazy(() => import('../views/city/CityForm')));

/* Device */
const Device = Loadable(lazy(() => import('../views/device/DeviceMain')));
const DevicesList = Loadable(lazy(() => import('../views/device/DevicesList')));
const DeviceForm = Loadable(lazy(() => import('../views/device/DeviceForm')));

/* Industry */
const Industry = Loadable(lazy(() => import('../views/industry/IndustryMain')));
const IndustriesList = Loadable(lazy(() => import('../views/industry/IndustriesList')));
const IndustryForm = Loadable(lazy(() => import('../views/industry/IndustryForm')));
const IndustryMassiveActions = Loadable(lazy(() => import('../views/industry/MassiveActions')));
const ImportLocationIndustries = Loadable(lazy(() => import('../views/industry/ImportLocationIndustries')));

/* You araboxes */
const YourAraboxes = Loadable(lazy(() => import('../views/youraraboxes/YourAraboxesMain')));
const YourAraboxesOverview = Loadable(lazy(() => import('../views/youraraboxes/YourAraboxesOverview')));
const AraboxesLocationPreview = Loadable(lazy(() => import('../views/youraraboxes/araboxeslocations/AraboxesLocationPreview')));
const AraboxesLocationEdit = Loadable(lazy(() => import('../views/youraraboxes/araboxeslocations/AraboxLocationEdit')));

/* Your company */
const YourCompany = Loadable(lazy(() => import('../views/yourcompany/YourCompanyMain')));
const YourCompanyOverwiew = Loadable(lazy(() => import('../views/yourcompany/YourCompanyOverview')));

/* Your spots */
const YourSpots = Loadable(lazy(() => import('../views/yourspots/YourSpotsMain')));
const YourSpotsOverview = Loadable(lazy(() => import('../views/yourspots/YourSpotsOverview')));

/* Traffic */
const Traffic = Loadable(lazy(() => import('../views/traffic/TrafficMain')));
const TrafficOverview = Loadable(lazy(() => import('../views/traffic/TrafficOverview')));

/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/testing', exact: true, element: <Testing /> },
      {
        path: '/campaign',
        element: <Campaign />,
        children: [
          { path: '', element: <CampaignList /> },
          { path: 'import', exact: true, element: <CampaignImport importType="CAMPAIGN"/> },
          { path: 'preview/:uuid', exact: true, element: <CampaignPreview /> },

          { path: 'form/add', exact: true, element: <CampaignForm formType="CREATE"/> },
          { path: 'form/:uuid', exact: true, element: <CampaignForm formType="EDIT"/> },
        ]
      },
      {
        path: '/offer',
        element: <Offer visibleFor={[1,3,4,5,6,13]}/>,
        children:[
          { path: '', element: <OfferList /> },
          { path: 'import', element: <CampaignImport importType="OFFER" /> },
          { path: 'preview/:uuid', exact: true, element: <OfferPreview /> },
        ]
      },
      {
        path: '/your-araboxes',
        element: <YourAraboxes />,
        children:[
          { path: '', element: <YourAraboxesOverview /> },
          { path: 'location-preview/:uuid', element: <AraboxesLocationPreview /> },
          { path: 'location-edit/:uuid', exact: true, element: <AraboxesLocationEdit /> },
        ]
      },
      {
        path: '/your-spots',
        element: <YourSpots />,
        children:[
          { path: '', element: <YourSpotsOverview /> },
          // { path: '/location-preview/:uuid', element: <AraboxesLocationPreview /> },
          // { path: '/location-edit/:uuid', exact: true, element: <AraboxesLocationEdit /> },
        ]
      },
      {
        path: '/traffic',
        element: <Traffic />,
        children:[
          { path: '', element: <TrafficOverview /> },
          // { path: '/location-preview/:uuid', element: <AraboxesLocationPreview /> },
          // { path: '/location-edit/:uuid', exact: true, element: <AraboxesLocationEdit /> },
        ]
      },
      {
        path: '/your-company',
        element: <YourCompany />,
        children:[
          { path: '', element: <YourCompanyOverwiew /> },
          // { path: '/location-preview/:uuid', element: <AraboxesLocationPreview /> },
          // { path: '/location-edit/:uuid', exact: true, element: <AraboxesLocationEdit /> },
        ]
      },


      {
        path: '/user',
        element: <User visibleFor={[1]} />,
        children:[
          { path: '', element: <UsersList /> },
          {
            path: 'form',
            element:  <UserForm />,
            children:[
              { path: ':uuid', exact: true, element: <UserForm /> },
            ]
          },
        ]
      },
      {
        path: '/role',
        element: <Role visibleFor={[1]} />,
        children:[
          { path: '', element: <RolesList /> },
          {
            path: 'form',
            element:  <RoleForm />,
            children:[
              { path: ':uuid', exact: true, element: <RoleForm /> },
            ]
          },
        ]
      },
      {
        path: '/company',
        element: <Company visibleFor={[1]} />,
        children:[
          { path: '', element: <CompaniesList /> },
          {
            path: 'form',
            element:  <CompanyForm />,
            children:[
              { path: ':uuid', exact: true, element: <CompanyForm /> },
            ]
          },
        ]
      },
      {
        path: '/location',
        element: <Location visibleFor={[1]} />,
        children:[
          { path: '', element: <LocationsList /> },
          {
            path: 'form',
            element:  <LocationForm />,
            children:[
              { path: ':uuid', exact: true, element: <LocationForm /> },
            ]
          },
        ]
      },
      {
        path: '/city',
        element: <City visibleFor={[1]} />,
        children:[
          { path: '', element: <CitiesList /> },
          {
            path: 'form',
            element:  <CityForm />,
            children:[
              { path: ':uuid', exact: true, element: <CityForm /> },
            ]
          },
        ]
      },
      {
        path: '/device',
        element: <Device visibleFor={[1]} />,
        children:[
          { path: '', element: <DevicesList /> },
          {
            path: 'form',
            element:  <DeviceForm />,
            children:[
              { path: ':uuid', exact: true, element: <DeviceForm /> },
            ]
          },
        ]
      },
      {
        path: '/industry',
        element: <Industry visibleFor={[1]} />,
        children:[
          { path: '', element: <IndustriesList /> },
          {
            path: 'form',
            element:  <IndustryForm />,
            children:[
              { path: ':uuid', exact: true, element: <IndustryForm /> },
            ]
          },
          {
            path: 'massive-actions/:uuid',
            exact: true,
            element:  <IndustryMassiveActions />,
          },
          {
            path: 'import',
            exact: true,
            element:  <ImportLocationIndustries />,
          },
        ]
      },
      {
        path: '/user-profile',
        element: <Profile/>,
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/shared/campaign/:uuid',
    element: <SharedCampaign/>
  }
];

export default Router;
