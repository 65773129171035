import {arahubAPI} from "config/axios";
import {API_LOCATION_URL, API_ANALYTICS_URL, API_ARABOXCONFIG_URL} from "config";
import {mockResolve, sessionStorageHelper} from "helpers";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const getCities=async(companyId)=>{
    try{
        const {data} = await sessionStorageHelper({type:'GET',key: 'your-araboxes-cities'});
        if(data?.companyId === companyId){
            return data?.items||[];
        }
    }catch (e) {/** */}

    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}city/filter/location`,{
            params:{
                company_id: companyId,
            }
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getLocationsDevices=async({company=null,cityItems=[], query})=>{
    const companyId = `company_id=${company.id}`;
    const cIds = cityItems.filter(i=>i.isChecked).map(i=>`city_ids=${i.id}`).join('&');
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}location/equipment/info?${companyId}&${cIds}`,{
            params:{
                page: query?.page||1,
                size: query?.size||50,
                filter: query?.filter||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getLocationsDevicesStats=async(_params)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}statistics/device`,_params);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }

}

const getLocationsDevicesStatsTotal=async(_params)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}statistics/device/total`,_params);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


/** 1) Main chart [people / cars / buses] */
const getLocationsDevicesPlot=async(_params)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}plot/device/audience`, _params);
        // return mockResolve(true,8000,data); /** TEST MOCK */
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

/** 2) People chart [people net/gross] */
const getLocationDevicesPeopleNetGrossPlot=async(_params)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}plot/device/net-gross`,_params);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

/** 3) Day to day [people net/gross] */
const getLocationDevicesDayToDayPlot=async(_params)=>{
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}plot/device/weekday`,_params);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

/** 4) AGE GENDER plots [new] */
const getLocationDevicesAgeGenderPlot=async(_params)=>{
    // params:
    // date_from, date_to, location_uuids:['557b1402-43...'], city_ids:[7], company_id:7,aggregation: "DAY"
    try{
        const {data} = await arahubAPI.post(`${API_ANALYTICS_URL}plot/people/age-gender`,{
            ..._params,
            time_of_day: _params.aggregation === 'DAY' ? _params?.time_of_day??'all' : 'all',
            aggregation: (_params.aggregation === 'RANGE_HOUR') ? 'HOUR' : _params.aggregation,
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

// new way to get image [src/redux/resources/araboxlive/api.js]
const getAraboxViewImage=async(arabox_id)=>{
    try{
        if(arabox_id?.length>2){
            // const {data} = await arahubAPI.get(`${API_ARABOXCONFIG_URL}arabox/panorama/${arabox_id}`);
            const {data} = await arahubAPI.get(`${API_LOCATION_URL}location/${arabox_id}/image`);
            return data;
        }
        return {panorama:"https://www.planetizen.com/files/images/TX%20Austin%20Guadalupe%20large.jpg",params:'EXAMPLE'}

    }catch(err){
        return Promise.reject(err.response);
    }
}

const getLocationReport=async(_params)=>{

    let _url =  _params?.rangeFormat === 'HOURLY' ? 'device/hourly' : 'screen';

    if(_params?.rawData){
        _url = `admin/${_url}`;
    }
    try{
        // const {data}= await arahubAPI.post(`${API_ANALYTICS_URL}report/${_url}`,_params,{responseType:'blob'});

        const res = await arahubAPI.post(`${API_ANALYTICS_URL}report/${_url}`,_params,{responseType:'blob'});
        const {data}= res;

        const href = URL.createObjectURL(data);
        const link = document.createElement('a');
        const fileName = res.headers.get('content-disposition')?.split('=')[1] || 'screen_report';
        link.href = href;
        link.setAttribute('download', fileName);

        return link;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const yourAraboxesService ={
    getCities,
    getLocationsDevices,
    getLocationsDevicesStats,
    getLocationsDevicesStatsTotal,

    // plots
    getLocationsDevicesPlot,
    getLocationDevicesPeopleNetGrossPlot,
    getLocationDevicesDayToDayPlot,
    getLocationDevicesAgeGenderPlot,

    getAraboxViewImage,
    getLocationReport,
}


export default yourAraboxesService;