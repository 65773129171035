import {call, put, takeLatest} from "redux-saga/effects";
import Cookies from "universal-cookie";
import {cToast} from "helpers";
import i18n from "i18next";
import userService from "./api";
import {userActions} from "./data";

const cookies = new Cookies();
function* authenticateUser({payload}) {

    const formData = new FormData();
    formData.append("username", payload.username);
    formData.append("password", payload.password);

    try{
        const data = yield call(userService.auth, formData);
        yield put(userActions.authenticateSuccess(data));
        yield cToast({msg: i18n.t("Login success"),variant:'success'});

    }catch (err) {
        yield put(userActions.authenticateFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchUser({payload}){
    try{
        const data = yield call(userService.getUser,payload);
        yield put(userActions.fetchUserSuccess(data));
    }catch (err) {
        yield put(userActions.fetchUserFailure(err))
    }
}

function* logoutUser({payload}){
    try{
        const data = yield call(userService.logout,payload);
        yield put(userActions.logoutSuccess(data))
    }catch (err) {
        yield put(userActions.logoutFailure(err))
    }
}

function* fetchUsers({payload}){
    try{
        const data = yield call(userService.getUsers,payload);
        yield put(userActions.fetchUsersSuccess(data))
    }catch (err) {
        yield put(userActions.fetchUsersFailure(err))
    }
}

function* createUser({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(userService.createUser,_data);
        yield put(userActions.createUserSuccess(data));
        yield navigate('/user');
        yield cToast({msg: i18n.t("User was created"),variant:'success'});
    }catch (err) {
        yield put(userActions.createUserFailure(err))
    }
}

function* updateUser({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(userService.updateUser, {userData:_data, id});
        yield put(userActions.updateUserSuccess(data));
        yield cToast({msg: i18n.t("User was updated"),variant:'success'});
        if(navigate){
            yield navigate('/user');
        }
    }catch (err) {
        yield put(userActions.updateUserFailure(err))
    }
}

function* deleteUser({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(userService.deleteUser,id);
        yield put(userActions.deleteUserSuccess(data));
        yield navigate('/user');
        yield cToast({msg: i18n.t("User was deleted"),variant:'warning'});
    }catch (err) {
        yield put(userActions.deleteUserFailure(err))
    }
}

function* fetchRefreshToken({payload}){
    const{
        refreshToken,
    }=payload;
    try{
        const {access_token} = yield call(userService.getAccessToken,refreshToken);
        yield cookies.set('access_token',access_token,{path:'/', httpOnly: false, hostOnly: false, sameSite: false});
    }catch (err) {
        // log out
        yield cToast({msg: i18n.t("Session was expired"),variant:'error'});
        yield put(userActions.logout());
    }
}

function* changePassword({payload}){
    try{
        const data = yield call(userService.changePassword,payload);
        yield cToast({msg: i18n.t("Password has been changed. Now the logout will occur"),variant:'success'});
        yield put(userActions.changePasswordSuccess(data));
    }catch (err) {
        yield cToast({msg: i18n.t(err?.data?.detail||"Something went wrong"),variant:'error'});
        yield put(userActions.changePasswordFailure(err));
    }
}

export default function* userSaga() {
    yield takeLatest(userActions.authenticate.type, authenticateUser);
    yield takeLatest(userActions.fetchUser.type, fetchUser);
    yield takeLatest(userActions.logout.type, logoutUser);
    yield takeLatest(userActions.fetchUsers.type, fetchUsers);
    yield takeLatest(userActions.createUser.type, createUser);
    yield takeLatest(userActions.updateUser.type, updateUser);
    yield takeLatest(userActions.deleteUser.type, deleteUser);
    yield takeLatest(userActions.refreshAuthToken.type, fetchRefreshToken);
    yield takeLatest(userActions.changePassword.type, changePassword);
}