import React,{lazy, Suspense} from "react";
import Loadable from "layouts/full-layout/loadable/Loadable";
import PropTypes from "prop-types";

const LightTheme = Loadable(lazy(() => import('helpers/calendar/ThemeLight')));
const DarkTheme = Loadable(lazy(() => import('helpers/calendar/ThemeDark')));
const CalendarThemeProvider = (props)=>{
    const{
        children,
        activeMode
    }=props;

    return(
        <>
            <Suspense fallback={()=>null}>
                {activeMode === 'dark' ? (<DarkTheme/>) : (<LightTheme/>)}
            </Suspense>
            {children}
        </>
    )
}

CalendarThemeProvider.propTypes={
    children: PropTypes.node,
    activeMode: PropTypes.string,
}

export default CalendarThemeProvider;
