import {call, takeLatest, take, put} from "redux-saga/effects";
import {cToast} from "helpers";
import i18n from "i18next";
import {wsActions} from "./data";
import wsService from "./api";

let socketChannel = null;



function* initWs({payload}){
    let socket;
    try{
        socket = yield call(wsService.createWebSocketConnection,payload);
        socketChannel = yield call(wsService.createSocketChannel, socket);


        while (true) {
            try {
                // An error from socketChannel will cause the saga jump to the catch block
                const {type, _payload} = yield take(socketChannel);
                switch (type){
                    case 'path':{
                        yield put(wsActions.putPathData(_payload));
                        break;
                    }
                    case 'realtime':{
                        yield put(wsActions.putRealtimeData(_payload));
                        break;
                    }
                    case 'CONNECT':{
                        yield put(wsActions.turnOnWsSuccess(_payload));
                        break;
                    }
                    default: break;
                }
            } catch(err) {
                // socketChannel is still open in catch block
                // if we want end the socketChannel, we need close it explicitly
                yield put(wsActions.turnOnWsFailure());
                yield socketChannel.close();
                yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
            }
        }

    }catch (err) {
        // yield err websocketa
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
        yield socketChannel.close();
    }
}

function* wsClose(){
    yield socketChannel.close();
    yield socketChannel = null;
}

export default function * wsSaga(){
        yield takeLatest(wsActions.turnOnWs.type,initWs);
        yield takeLatest(wsActions.turnOffWs.type,wsClose);
}

// opcja z cancel -> to cancelowanie sagi, która pomimo zamkniecia websocketa może jeszcze zrzucać eventy z kolejki.