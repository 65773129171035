import {API_LOCATION_URL} from "config";
import {arahubAPI} from "config/axios";

const ROUTE = 'device';

const getDevices=async(query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}`,{
            params:{
                page: query.page,
                size: query.size,
                sort_by: query?.sort_by||null,
                order: query?.order||null,
                filter:query?.filter||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const getDevice = async (id) =>{
    /** not available right now BACKEND */
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"getDevice - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}/${id}`);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}


const createDevice = async (deviceData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}${ROUTE}`,deviceData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const updateDevice=async({deviceData, deviceId})=>{
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"updateDevice - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.put(`${API_LOCATION_URL}${ROUTE}/${deviceId}`,deviceData);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}


const deleteDevice=async(id)=>{
    /** not available right now BACKEND */
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"deleteDevice - OK"});
        },300);
    });
    // try{
    //     const {data} = await arahubAPI.delete(`${API_LOCATION_URL}${ROUTE}/${id}`);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}



const deviceService = {
    getDevices,
    getDevice,
    createDevice,
    updateDevice,
    deleteDevice,
}

export default deviceService;