import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import locationService from "./api";
import {locationActions} from "./data";
import {companyActions} from "../company/data";



function* fetchLocations({payload}){
    try{
        const data = yield call(locationService.getLocations,payload);
        yield put(locationActions.fetchLocationsSuccess(data));

        // fetch companies
        yield put(companyActions.fetchCompanies({}));
    }catch (err) {
        yield put(locationActions.fetchLocationsFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchLocation({payload}){
    try{
        const data = yield call(locationService.getLocation, payload);
         yield put(locationActions.fetchLocationSuccess(data));
    }catch (err) {
        yield put(locationActions.fetchLocationFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* createLocation({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(locationService.createLocation,_data);
        yield put(locationActions.createLocationSuccess(data));
        yield navigate('/location');
        yield cToast({msg: i18n.t("Location was created"),variant:'success'});
    }catch (err) {
        yield put(locationActions.createLocationFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* updateLocation({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(locationService.updateLocation,{
            locationData: _data,
            locationId: id,
        });
        yield put(locationActions.updateLocationSuccess(data));
        yield navigate('/location');
        yield cToast({msg: i18n.t("Location was updated"),variant:'success'});
    }catch (err) {
        yield put(locationActions.updateLocationFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* deleteLocation({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(locationService.deleteLocation,id);
        yield put(locationActions.deleteLocationSuccess(data));
        yield navigate('/location');
        yield cToast({msg: i18n.t("Location was deleted"),variant:'warning'});
    }catch (err) {
        yield put(locationActions.deleteLocationFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchLocationPanoramaImagesList({payload}){
    try{
        const data = yield call(locationService.getPanoramaImagesList,payload);
        yield put(locationActions.fetchLocationPanoramaImagesListSuccess(data));
    }catch (err) {
        yield put(locationActions.fetchLocationPanoramaImagesListFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchLocationPanoramaImage({payload}){
    try{
        const data = yield call(locationService.getLocationPanoramaImage,payload);
        yield put(locationActions.fetchLocationPanoramaImageSuccess(data));
    }catch (err) {
        if(err?.data?.detail?.substring("Please set image")){
            yield put(locationActions.fetchLocationPanoramaImageFailure(err?.data?.detail));
        }else{
            yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
            yield put(locationActions.fetchLocationPanoramaImageFailure(err));
        }
    }
}

function* updateLocationPanoramaImage({payload}){
    try{
        const data = yield call(locationService.updateLocationPanoramaImage,payload);
        yield put(locationActions.updateLocationPanoramaImageSuccess(data));
        yield cToast({msg: i18n.t("Location image was updated successfully"),variant:'success'});
    } catch (err) {
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
        yield put(locationActions.updateLocationPanoramaImageFailure(err));
    }
}

export default function* locationSaga(){
    yield takeLatest(locationActions.fetchLocations.type, fetchLocations);
    yield takeLatest(locationActions.fetchLocation.type, fetchLocation);
    yield takeLatest(locationActions.createLocation.type, createLocation);
    yield takeLatest(locationActions.updateLocation.type, updateLocation);
    yield takeLatest(locationActions.deleteLocation.type, deleteLocation);
    yield takeLatest(locationActions.fetchLocationPanoramaImagesList.type, fetchLocationPanoramaImagesList);
    yield takeLatest(locationActions.fetchLocationPanoramaImage.type, fetchLocationPanoramaImage);
    yield takeLatest(locationActions.updateLocationPanoramaImage.type, updateLocationPanoramaImage);
}