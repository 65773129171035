export const TOKEN_INTERVAL = 5;
export const LOCALE = "pl-PL";

/** URL : https://users.arahub.ai/api/v1/ */
export const API_USERS_URL = "https://users.arahub.ai/api/v1/";

/** URL : https://analytics.arahub.ai/api/v1/ */
export const API_ANALYTICS_URL = "https://analytics.arahub.ai/api/v1/";

/** URL : https://location.arahub.ai/api/v1/ */
export const API_LOCATION_URL = "https://location.arahub.ai/api/v1/";

/** URL : https://arabox-config.arahub.ai/api/v1/ */
export const API_ARABOXCONFIG_URL = "https://arabox-config.arahub.ai/api/v1/";

/** URL : https://platforma.arahub.ai/api/v1/ */
export const API_PLATFORMA_URL = "https://platforma.arahub.ai/api/";

/** URL : "https://sc-gemius-api.arahub.ai/api/v1/ */
export const API_GEMIUS_URL = "https://sc-gemius-api.arahub.ai/api/v1/";


// API v2
/** URL : https://location.arahub.ai/api/v1/ */
export const API_LOCATION_URL_V2 = "https://location.arahub.ai/api/v2/";


// websocket v1
/** URL : wss://platforma.arahub.ai/ws */
export const API_WEBSOCKET_ARAHUB = "wss://platforma.arahub.ai/ws";

export const EMISSIONS_PER_DAY = 1080;
export const ONE_EMISSION_PRICE = 0.086;

export const CAMPAIGN_STATUSES = [
    {key:'draft', name: "Draft"},
    {key:'new', name: "New"},
    // {key:'offer', name: "Offer"}, // => dodany
    {key:'ready', name: "Ready"}, // => dodany
    {key:'accepted', name: "Accepted"}, // => dodany
    {key:'in_progress', name: "In progress"}, // => dodany
   //  {key:'pending', name: "Pending"},
    {key:'end', name: "End"},
];

export const BASE_PRICE_RANGES = [
    {min:1, max: 70, priceFactor: 0.086},
    {min:71, max: 140, priceFactor: 0.085},
    {min:141, max: 210, priceFactor: 0.084},
    {min:211, max: 280, priceFactor: 0.082},
    {min:281, max: 350, priceFactor: 0.073},
    {min:351, max: 525, priceFactor: 0.070},
    {min:526, max: 700, priceFactor: 0.069},
    {min:701, max: 875, priceFactor: 0.067},
    {min:876, max: 1050, priceFactor: 0.066},
    {min:1051, max: 1225, priceFactor: 0.063},
    {min:1226, max: 1400, priceFactor: 0.062},
    {min:1401, max: 1575, priceFactor: 0.061},
    {min:1576, max: 1750, priceFactor: 0.060},
    {min:1751, max: 1925, priceFactor: 0.059},
    {min:1926, max: 2100, priceFactor: 0.058},
    {min:2101, max: 2450, priceFactor: 0.057},
    {min:2451, max: 2800, priceFactor: 0.056},
    {min:2801, max: 3150, priceFactor: 0.055},
    {min:3151, max: 3500, priceFactor: 0.055},
    {min:3501, max: 3850, priceFactor: 0.054},
    {min:3851, max: 4200, priceFactor: 0.054},
    {min:4201, max: 4725, priceFactor: 0.054},
    {min:4726, max: 5250, priceFactor: 0.053},
    {min:5251, max: 5775, priceFactor: 0.051},
    {min:5776, max: 6300, priceFactor: 0.050},
    {min:6301, max: 7000, priceFactor: 0.048},
    {min:7001, max: 7700, priceFactor: 0.047},
    {min:7701, max: 8400, priceFactor: 0.046},
    {min:8401, max: 12400, priceFactor: 0.045},
    {min:12401, max: 40000, priceFactor: 0.043},
    {min:40001, max: 99999999999, priceFactor: 0.043},
];
