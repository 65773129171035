import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { ToastContainer} from 'react-toastify';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import CalendarThemeProvider from "./helpers/calendar/CalendarThemeProvider";

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
          <ToastContainer />
          <ScrollToTop/>
        <CalendarThemeProvider activeMode={customizer.activeMode}>
          {routing}
        </CalendarThemeProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
