import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import cityService from "./api";
import {cityActions} from "./data";

function* fetchCities({payload}){
    try{
        const data = yield call(cityService.getCities,payload);
        yield put(cityActions.fetchCitiesSuccess(data));
    }catch (err) {
        yield put(cityActions.fetchCitiesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchCity({payload}){
    try{
        const data = yield call(cityService.getCity, payload);
        yield put(cityActions.fetchCitySuccess(data));
    }catch (err) {
        yield put(cityActions.fetchCitiesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* createCity({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(cityService.createCity,_data);
        yield put(cityActions.createCitySuccess(data));
        yield navigate('/city');
        yield cToast({msg: i18n.t("City was created"),variant:'success'});
    }catch (err) {
        yield put(cityActions.createCityFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* updateCity({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(cityService.updateCity,{
            cityData: _data,
            cityId: id,
        });
        yield put(cityActions.updateCitySuccess(data));
        yield navigate('/city');
        yield cToast({msg: i18n.t("City was updated"),variant:'success'});
    }catch (err) {
        yield put(cityActions.updateCityFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* deleteCity({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(cityService.deleteCity,id);
        yield put(cityActions.deleteCitySuccess(data));
        yield navigate('/city');
        yield cToast({msg: i18n.t("City was deleted"),variant:'warning'});
    }catch (err) {
        yield put(cityActions.deleteCityFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


export default function* citySaga(){
    yield takeLatest(cityActions.fetchCities.type, fetchCities);
    yield takeLatest(cityActions.fetchCity.type, fetchCity);
    yield takeLatest(cityActions.createCity.type, createCity);
    yield takeLatest(cityActions.updateCity.type, updateCity);
    yield takeLatest(cityActions.deleteCity.type, deleteCity);
}