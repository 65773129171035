import React, {useCallback} from 'react';
import {Zoom, Box, Fab, useScrollTrigger} from "@mui/material";
import { KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";

const ScrollToTop=()=> {

    const trigger = useScrollTrigger({
        // target: document.body,
        // disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
        document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Zoom in={trigger}>
            <Box
                role="presentation"
                sx={{
                    position: "fixed",
                    bottom: 15,
                    right: 5,
                    zIndex: 999,
                }}
            >
                <Fab
                    sx={{
                        transform: 'scale(0.8)',
                        opacity:.7,
                        '&:hover':{
                            opacity:1,
                        }
                    }}
                    onClick={scrollToTop}
                    color="primary"
                    size="small"
                    aria-label="scroll back to top"
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    )
 }

export default ScrollToTop;