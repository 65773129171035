import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState={
    gemiusCampaigns:{
        isPending: false,
        error: null,
        items:{
            waiting:[],
            current:[],
            finished:[],
        },
    }
}

const slice = createSlice({
    name:'gemius',
    initialState,
    reducers:{
        fetchGemiusCampaigns:(state,{payload})=>{
            state.gemiusCampaigns.error = null;
            state.gemiusCampaigns.items = initialState.gemiusCampaigns.items;
            state.gemiusCampaigns.isPending = true;
        },

        fetchGemiusCampaignsSuccess:(state,{payload})=>{

            let _data={
                waiting:[],
                current:[],
                finished:[],
            }

            if(payload.length>0){

                _data= payload.reduce((acc, item)=>{
                    acc[`${item.status}`].push(item);
                    acc[`${item.status}`].sort((a,b)=> a.name.localeCompare(b.name));
                    return acc;
                },{
                    waiting:[],
                    current:[],
                    finished:[],
                });
            }
            state.gemiusCampaigns.items = _data;
            state.gemiusCampaigns.isPending = false;
        },

        fetchGemiusCampaignsFailure:(state,{payload})=>{
            state.gemiusCampaigns.error = payload;
            state.gemiusCampaigns.items = initialState.gemiusCampaigns.items;
            state.gemiusCampaigns.isPending = false;
        },
    }
});

export const gemiusActions={
    fetchGemiusCampaigns: createAction("gemius/fetchGemiusCampaigns"),
    fetchGemiusCampaignsSuccess: createAction("gemius/fetchGemiusCampaignsSuccess"),
    fetchGemiusCampaignsFailure: createAction("gemius/fetchGemiusCampaignsFailure"),
}

export default slice.reducer;