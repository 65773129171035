import {API_ARABOXCONFIG_URL, API_LOCATION_URL} from "config";
import {arahubAPI} from "config/axios";

const ROUTE = 'location';

const getLocations=async(query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}`,{
            params:{
                page: query?.page||1,
                size: query?.size||50,
                sort_by: query?.sort_by||null,
                order: query?.order||null,
                filter: query?.filter||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const getLocation = async (uuid) =>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}/${uuid}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}



const createLocation = async (locationData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}${ROUTE}`,locationData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const updateLocation=async({locationData, locationId})=>{
    try{
        const {data} = await arahubAPI.put(`${API_LOCATION_URL}${ROUTE}/${locationId}`,locationData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const deleteLocation=async(id)=>{

    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve({data:"updateRole - OK"});
        },300);
    });

    // try{
    //     const {data} = await arahubAPI.delete(`${API_LOCATION_URL}${ROUTE}/${id}`);
    //     return data;
    // }catch(err){
    //     return Promise.reject(err.response);
    // }
}


const getPanoramaImagesList=async(arabox_id)=>{
    try{
        const {data} = await arahubAPI.get(`${API_ARABOXCONFIG_URL}panorama/${arabox_id}/list`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}

const getLocationPanoramaImage=async(location_uuid)=>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}/${location_uuid}/image`);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}


const updateLocationPanoramaImage=async({location_uuid,image_sign_url})=>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}${ROUTE}/image`,{location_uuid,image_sign_url});
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}



const locationService = {
    getLocations,
    getLocation,
    createLocation,
    updateLocation,
    deleteLocation,
    getPanoramaImagesList,
    getLocationPanoramaImage,
    updateLocationPanoramaImage,
}

export default locationService;