import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Typography, useTheme
} from "@mui/material";
import {
    Bolt as BoltIcon,LocalOffer as LocalOfferIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {DataGrid} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "react-redux";
import {locationActions} from "redux/resources/location/data";

import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";


const MassiveActions=()=> {
    const {t}=useTranslation();
    const {palette} = useTheme();
    const {uuid} = useParams();
    const navigate = useNavigate();
    const {state} = useLocation();
    const dispatch = useDispatch();
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const {companies} = useSelector(s=>s.company);
    const {locations} = useSelector(s=>s.location);
    const{
        isPending,
        query,
        locationItems,
    }=locations;
    const{
        isPending: companiesIsPending,
        companyItems,
    }=companies;


    useEffect(()=>{
        const nQuery={...query, size:5}
        dispatch(locationActions.fetchLocations(nQuery));
    },[query.size, query.page, query.filter]);

    useEffect(()=>{
        if(!isPending){
            setRowSelectionModel([]);
        }
    },[isPending]);

    const returnCompanyName=(companyId)=>{
        return companyItems.find(c => c.id === companyId)?.name??'-';
    }

    const locationsList = locationItems?.length>0 ? locationItems.map((item,index)=>({
        id: item.id,
        uuid: item.uuid,
        name: item.name,
        address: item.address,
        companyId: item.company_id,
        status: item.status,
    })):[];

    const columns = [
        {
            field: 'selected',
            headerName: t('Selected'),
            align:"center",
            headerAlign: "center",
            minWidth: 120,
            maxWidth: 120,
            valueGetter:({row:{id}})=> rowSelectionModel.some(i=> i === id),
            renderCell:({row:{id}})=> (
                rowSelectionModel.some(i=> i === id) ?
                    <CheckCircleIcon fontSize="small" sx={{color: palette.success.dark}}/> :
                    <CancelIcon fontSize="small" sx={{color: palette.error.dark}}/>
            )
        },
        { field: 'id', headerName: 'ID',  disableColumnMenu: true, width: 60, align: 'center', headerAlign: 'center'},
        {
            field: 'companyId',
            headerName: t('Company name'),
            minWidth: 150,
            maxWidth: 250,
            flex:1,
            valueGetter:({row:{companyId}})=> returnCompanyName(companyId),
            renderCell:({row:{companyId}})=> <Typography variant="h5" color="primary" fontWeight="bold">{`${returnCompanyName(companyId)}`}</Typography>,
        },
        {
            field: 'name',
            headerName: t('Name'),
            minWidth: 150,
            flex:1,
            valueGetter:({row:{name}})=> name,
            // renderCell:({row:{name}})=> <Typography variant="h6" color="secondary" >{`${name}`}</Typography>,
        },
        {
            field: 'address',
            headerName: t('Address'),
            minWidth: 150,
            flex:1,
            valueGetter:({row:{address}})=> address??'-',
        },
        {
            field: 'status',
            headerName: t('Status'),
            renderCell:({row: {status}})=> (
                status === 'active' ?
                    <div style={{width:12, height:12, borderRadius:"50%", backgroundColor: palette.success.dark}}/> :
                    <div style={{width:12, height:12, borderRadius:"50%", backgroundColor: palette.error.dark}}/>
            ),
            align: 'center',
            headerAlign: 'center',
            minWidth: 150,
            maxWidth: 200,
            flex:1
        },
    ];

     return (
         <Card sx={{m:0, p:.5}}>
             <CardHeader
                 sx={{pt:1}}
                 avatar={
                     <LocalOfferIcon fontSize="large" color="primary"/>
                 }
                 title={<Typography variant="h2" color="primary">{state?.industryName||'-'}</Typography>}
                 subheader={`${t("Industry id")}: ${uuid}`}
                 action={
                     <Chip
                         icon={<BoltIcon fontSize="small" sx={{transform: 'rotate(20deg)'}}/>}
                         color="secondary"
                         sx={{mr:1}}
                         variant="outlined"
                         size="large"
                         label={
                             <Typography variant="h4" color="secondary">
                                 {t('Massive actions')}
                             </Typography>}
                     />
                 }
             />
             <CardContent>
                 <Box sx={{height:"auto"}}>
                     <DataGrid
                         autoHeight
                         // hideFooter
                         density="compact"
                         columns={columns}
                         rows={locationsList}
                         loading={isPending || companiesIsPending}
                         components={{
                             NoRowsOverlay: CustomNoRowsOverlay,
                         }}
                         // checkboxSelection
                         isRowSelectable={()=>true}
                         selectionModel={rowSelectionModel}
                         onSelectionModelChange={(ids) => {
                             setRowSelectionModel(ids);
                         }}
                     />
                 </Box>
             </CardContent>
             <CardActions>
                 <Box sx={{display:"flex", flex:1, justifyContent: 'space-between', mx:1, mb:1}}>
                     <Box>
                         <Button
                             variant="outlined"
                             onClick={() => navigate('/industry')}
                             color="primary"
                         >{t('Back')}</Button>
                     </Box>
                     <Box>
                         <Button
                             sx={{ml:2, display:"none"}}
                             onClick={()=>{
                                 // formik.handleSubmit()
                             }}
                             variant="contained"
                             color="success"
                         >{t('Save')}</Button>
                     </Box>
                 </Box>
             </CardActions>
         </Card>
     )
 }

export default MassiveActions;