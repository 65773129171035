import i18n from "i18next";
import {call, put, takeLatest} from "redux-saga/effects";
import {cToast} from "helpers";
import roleService from "./api";
import {roleActions} from "./data";



function* fetchRoles({payload}){
    try{
        const data = yield call(roleService.getRoles,payload);
        yield put(roleActions.fetchRolesSuccess(data));
    }catch (err) {
        yield put(roleActions.fetchRolesFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchRole({payload}){
    try{
        const data = yield call(roleService.getRole, payload);
        yield put(roleActions.fetchRoleSuccess(data));
    }catch (err) {
        yield put(roleActions.fetchRoleFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* createRole({payload}){
    const{
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(roleService.createRole,_data);
        yield put(roleActions.createRoleSuccess(data));
        yield navigate('/role');
        yield cToast({msg: i18n.t("Role was created"),variant:'success'});
    }catch (err) {
        yield put(roleActions.createRoleFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* updateRole({payload}){
    const{
        id,
        _data,
        navigate,
    }=payload;
    try{
        const data = yield call(roleService.updateRole,{
            roleData: _data,
            roleId: id,
        });
        yield put(roleActions.updateRoleSuccess(data));
        yield navigate('/role');
        yield cToast({msg: i18n.t("Role was updated"),variant:'success'});
    }catch (err) {
        yield put(roleActions.updateRoleFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}


function* deleteRole({payload}){
    const{
        id,
        navigate,
    }=payload;
    try{
        const data = yield call(roleService.deleteRole,id);
        yield put(roleActions.deleteRoleSuccess(data));
        yield navigate('/role');
        yield cToast({msg: i18n.t("Role was deleted"),variant:'warning'});
    }catch (err) {
        yield put(roleActions.deleteRoleFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* roleSaga(){
    yield takeLatest(roleActions.fetchRoles.type, fetchRoles);
    yield takeLatest(roleActions.fetchRole.type, fetchRole);
    yield takeLatest(roleActions.createRole.type, createRole);
    yield takeLatest(roleActions.updateRole.type, updateRole);
    yield takeLatest(roleActions.deleteRole.type, deleteRole);
}