import {API_LOCATION_URL} from "config";
import {arahubAPI} from "config/axios";
import {mockResolve, sessionStorageHelper} from "helpers";

const ROUTE = 'industry';

const getIndustries=async(query)=>{

    try{
        if(query?.isExternal){
            const {data} = await sessionStorageHelper({type:'GET',key: 'industries'}) ||
                await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}`,{
                params:{
                    page: query?.page??1,
                    size: query?.size??25,
                }
            });
            return data;
        }

        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}`,{
            params:{
                page: query?.page??1,
                size: query?.size??25,
            }
        });
        return data;


    }catch(err){
        return Promise.reject(err.response);
    }
}

const getIndustry = async (id) =>{
    try{
        const {data} = await arahubAPI.get(`${API_LOCATION_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const createIndustry = async (industryData) =>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}${ROUTE}`,industryData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateIndustry=async({industryData, industryId})=>{
    try{
        const {data} = await arahubAPI.put(`${API_LOCATION_URL}${ROUTE}/${industryId}`,industryData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const deleteIndustry=async(id)=>{
    try{
        const {data} = await arahubAPI.delete(`${API_LOCATION_URL}${ROUTE}/${id}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const uploadLocationIndustriesCsv=async(formData)=>{
    try{
        const {data} = await arahubAPI.post(`${API_LOCATION_URL}location/${ROUTE}/import`,formData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const industryService = {
    getIndustries,
    getIndustry,
    createIndustry,
    updateIndustry,
    deleteIndustry,
    uploadLocationIndustriesCsv,
}

export default industryService;