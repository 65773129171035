import {createAction, createSlice} from "@reduxjs/toolkit";

const initialState = {
    cities:{
        isPending: false,
        error: null,
        cityItems:[],
        query:{
            page: 1,
            pages:1,
            size:25,
            total:0,
            sort_by: null,
            order:null,
            filter:null,
            fieldFilter: null,
        },
    },
    cityItem:{
        isPending: false,
        error: null,
        item:{},
    }
}

const slice = createSlice({
   name: 'city',
   initialState,
   reducers:{

       setQuery: (state,{payload})=>{
           const{
               pageSize,
               page,
               filter,
               fieldFilter,
               sort_by,
               order,
           }=payload;
           state.cities.query.size = pageSize;
           state.cities.query.page = page;
           state.cities.query.filter = filter;
           state.cities.query.fieldFilter = fieldFilter;
           state.cities.query.sort_by = sort_by;
           state.cities.query.order = order;
       },

       fetchCities:(state,{payload})=>{
           state.cities.error = null;
           state.cities.cityItems = [];
           state.cities.isPending = true;
       },
       fetchCitiesSuccess:(state,{payload})=>{
           const{
               items,
               total,
               page,
               pages,
               size,
           }=payload;

           state.cities.query.total = total;
           state.cities.query.page = page;
           state.cities.query.pages = pages;
           state.cities.query.size = size;

           state.cities.error = null;
           state.cities.cityItems = items;
           state.cities.isPending = false;
       },
       fetchCitiesFailure:(state,{payload})=>{
           state.cities.error = payload;
           state.cities.cityItems = [];
           state.cities.isPending = false;
       },


       fetchCity:(state,{payload})=>{
           state.cityItem.error = null;
           state.cityItem.item = {};
           state.cityItem.isPending = true;
       },
       fetchCitySuccess:(state,{payload})=>{
           state.cityItem.error = null;
           state.cityItem.item = payload;
           state.cityItem.isPending = false;
       },
       fetchCityFailure:(state,{payload})=>{
           state.cityItem.error = payload;
           state.cityItem.item = {};
           state.cityItem.isPending = false;
       },


       createCity:(state,{payload})=>{
           //
       },
       createCitySuccess:(state,{payload})=>{
           //
       },
       createCityFailure:(state,{payload})=>{
           state.cityItem.error = payload;
       },


       updateCity:(state,{payload})=>{
           //
       },
       updateCitySuccess:(state,{payload})=>{
           //
       },
       updateCityFailure:(state,{payload})=>{
           state.cityItem.error = payload;
       },


       deleteCity:(state,{payload})=>{
           //
       },
       deleteCitySuccess:(state,{payload})=>{
           //
       },
       deleteCityFailure:(state,{payload})=>{
           state.cityItem.error = payload;
       }
   }
});

export const cityActions={
    setQuery: createAction("city/setQuery"),

    fetchCities: createAction("city/fetchCities"),
    fetchCitiesSuccess: createAction("city/fetchCitiesSuccess"),
    fetchCitiesFailure: createAction("city/fetchCitiesFailure"),

    fetchCity: createAction("city/fetchCity"),
    fetchCitySuccess: createAction("city/fetchCitySuccess"),
    fetchCityFailure: createAction("city/fetchCityFailure"),

    createCity: createAction("city/createCity"),
    createCitySuccess: createAction("city/createCitySuccess"),
    createCityFailure: createAction("city/createCityFailure"),

    updateCity: createAction("city/updateCity"),
    updateCitySuccess: createAction("city/updateCitySuccess"),
    updateCityFailure: createAction("city/updateCityFailure"),

    deleteCity: createAction("city/deleteCity"),
    deleteCitySuccess: createAction("city/deleteCitySuccess"),
    deleteCityFailure: createAction("city/deleteCityFailure"),
}


export default slice.reducer;