import {createAction, createSlice} from "@reduxjs/toolkit";
import {campaignElapsedDays, campaignLengthInDays, gemiusDateFormatter} from "helpers";

const initialState = {
    campaigns:[],
    isPending: false,
    error: null,
    isSuccess: false,
    query:{
        page: 1,
        pages:1,
        size:10,
        total:0,
        sort_by: 'created_at',
        order:'desc',
        filter:null,
        fieldFilter: null,
    },
    item:{},
    itemCampaignSpot:{
        link: null,
        fileName: null,
        id: null,
        isPending: false,
        isUploading: false,
        spot_file_persist: false,
    },
    createEstimatedAudience:{
      isPending: false,
      error: null,
      total_audience:0,
      cpm:0,
      emissions_count:0,
      campaign_price:0,
      campaign_price_from_price_list:0,
      discount:0,
    },
    itemStatistics:{},
    itemEstimates:{},
    itemEstimatesIsPending:false,
    itemHelper:{
        campaignLengthInDays:0,
        elapsedDays:0,
    },
    itemGemiusStatistics:{
        isPending: false,
        error: null,
        periods:[],
        impressions:[],
        todayImpressions:0,
        totalEmissions:0,
    },
    citiesWithCount:[],
    // api v2
    totalNumberOfScreens:0,

    advancedScreensStats:{
        estimated_items:[],
        realized_items:[],
    },

    campaignAudienceStats:{
        isPending: false,
        error: null,
        data:{},
    }
}

const slice = createSlice({
    name: 'campaign',
    initialState,
    reducers:{

        reset: (state)=>{
            state.isSuccess = false;
        },

        setQuery: (state,{payload})=>{
            const{
                pageSize,
                page,
                filter,
                fieldFilter,
                sort_by,
                order,
            }=payload;
            state.query.size = pageSize;
            state.query.page = page;
            state.query.filter = filter;
            state.query.fieldFilter = fieldFilter;
            state.query.sort_by = sort_by;
            state.query.order = order;
        },


        fetchCampaigns: (state, {payload})=>{
            state.isPending = true;
            state.itemCampaignSpot = initialState.itemCampaignSpot;
        },
        fetchCampaignsSuccess: (state, {payload})=>{
            const{
                items,
                query:{
                    total,
                    page,
                    pages,
                    size,
                }
            }=payload;

            state.query.total = total;
            state.query.page = page;
            state.query.pages = pages;
            state.query.size = size;

            state.campaigns = items;
            state.isPending = false;
            state.error = null;
        },
        fetchCampaignsFailure: (state, {payload})=>{
            state.error = payload;
            state.isPending = false;
        },



        fetchCampaign:(state,{payload})=>{
            state.isPending = true;
        },
        fetchCampaignSuccess:(state,{payload})=>{
            state.item = payload;
            state.isPending = false;
            state.error = null;
        },
        fetchCampaignFailure:(state,{payload})=>{
            state.error = payload;
            state.isPending = false;
        },



        fetchCampaignStatistics:(state,{payload})=>{
            state.isPending = true;
        },
        fetchCampaignStatisticsSuccess:(state,{payload})=>{
            state.itemStatistics = payload;
            state.advancedScreensStats.realized_items = payload?.screen_audience??[]; // advanced screen stats for screens [admin only]
            state.isPending = false;
            state.error = null;
        },
        fetchCampaignStatisticsFailure:(state,{payload})=>{
            state.error = payload;
            state.isPending = false;
        },


        fetchCampaignEstimates:(state,{payload})=>{
            // state.isPending = true;
            state.itemEstimatesIsPending = true;
        },
        fetchCampaignEstimatesSuccess:(state,{payload})=>{
            state.itemEstimates = payload;
            state.advancedScreensStats.estimated_items = payload?.screen_audience??[]; // advanced screen stats for screens [admin only]
            // state.isPending = false;
            state.itemEstimatesIsPending = false
            state.error = null;
        },
        fetchCampaignEstimatesFailure:(state,{payload})=>{
            state.error = payload;
            // state.isPending = false;
            state.itemEstimatesIsPending = false
        },


        createCampaign:(state,{payload})=>{
            state.error = null;
            state.isPending = true;
        },
        createCampaignSuccess:(state,{payload})=>{
            state.isPending = false;
            state.error = null;
        },
        createCampaignFailure:(state,{payload})=>{
            state.isPending = false;
            state.error = payload;
        },


        updateCampaign:(state,{payload})=>{
            state.isPending = true;
            state.error = null;
        },
        updateCampaignSuccess:(state,{payload})=>{
            state.isPending = false;
            state.error = null;
        },
        updateCampaignFailure:(state,{payload})=>{
            state.isPending = false;
            state.error = payload;
        },


        fetchCampaignSpots:(state,{payload})=>{
            state.itemCampaignSpot = {...initialState.itemCampaignSpot, isPending: true};
        },
        fetchCampaignSpotsSuccess:(state,{payload})=>{
            state.itemCampaignSpot = {...payload, isPending: false, isUploading: false};
        },
        fetchCampaignSpotsFailure:(state,{payload})=>{
            state.error = payload;
            state.itemCampaignSpot.isPending = false;
        },


        uploadSpotFile:(state,{payload})=>{
            state.itemCampaignSpot.isUploading = true;
        },
        uploadSpotFileSuccess:(state,{payload})=>{
            state.itemCampaignSpot.isUploading = false;
        },
        uploadSpotFileFailure:(state,{payload})=>{
            state.itemCampaignSpot.isUploading = false;
            state.error = payload;
        },



        deleteCampaign:(state,{payload})=>{
            state.isPending = true;
        },
        deleteCampaignSuccess:(state,{payload})=>{
            state.isSuccess = true;
        },
        deleteCampaignFailure:(state,{payload})=>{
            state.error = payload;
            state.isPending = false;
        },

        fetchCitiesWithCount:(state,{payload})=>{
            //
        },
        fetchCitiesWithCountSuccess:(state,{payload})=>{

            // API v2
            state.citiesWithCount = payload.number_of_screens_per_city;
            state.totalNumberOfScreens = payload.total_number_of_screens;

            // API v1
            // state.citiesWithCount = payload;
        },
        fetchCitiesWithCountFailure:(state,{payload})=>{
            state.error = payload;
        },


        fetchUsedCityScreens:(state,{payload})=>{
            //
        },
        fetchUsedCityScreensSuccess:(state,{payload})=>{
            //
        },
        fetchUsedCityScreensFailure:(state,{payload})=>{
            state.error = payload;
        },

        fetchGemiusStatistics:(state,{payload})=>{
            state.itemGemiusStatistics = initialState.itemGemiusStatistics;
            state.itemGemiusStatistics.error = null;
            state.itemGemiusStatistics.isPending = true;
        },
        fetchGemiusStatisticsSuccess:(state,{payload})=>{
            if(payload?.statistics?.periodName === "General"){
                // total impressions
                state.itemGemiusStatistics.totalEmissions = payload?.statistics?.impressions??0;
            }else{
                // day emissions
                const progress = payload?.statistics.reduce((acc,item)=>{
                    if(item.impressions !== 'NULL'){
                        acc.periods.push(gemiusDateFormatter(item.period.toString()));
                        acc.impressions.push(item.impressions);
                        acc.todayImpressions += item.impressions;
                    }
                    return acc;
                },{
                    periods:[],
                    impressions: [],
                    todayImpressions:0,
                });
                state.itemGemiusStatistics.periods = progress.periods;
                state.itemGemiusStatistics.impressions = progress.impressions;
                state.itemGemiusStatistics.todayImpressions = progress.todayImpressions;
                }
                state.itemGemiusStatistics.isPending = false;
        },
        fetchGemiusStatisticsFailure:(state,{payload})=>{
            state.itemGemiusStatistics.isPending = false;
            state.itemGemiusStatistics.error = payload;
        },


        fetchEstimatedAudience:(state,{payload})=>{
            state.createEstimatedAudience.error = null;
            state.createEstimatedAudience.isPending = true;
        },
        fetchEstimatedAudienceSuccess:(state,{payload})=>{
            state.createEstimatedAudience.total_audience = payload.total_audience;
            state.createEstimatedAudience.cpm = payload.cpm;
            state.createEstimatedAudience.emissions_count = payload.emissions_count;
            state.createEstimatedAudience.campaign_price = payload.campaign_price;
            state.createEstimatedAudience.campaign_price_from_price_list = payload.campaign_price_from_price_list;
            state.createEstimatedAudience.discount = payload.discount;
            state.createEstimatedAudience.error = null;
            state.createEstimatedAudience.isPending = false;
        },
        fetchEstimatedAudienceFailure:(state,{payload})=>{
            state.createEstimatedAudience.total_audience = 0;
            state.createEstimatedAudience.cpm = 0;
            state.createEstimatedAudience.emissions_count = 0;
            state.createEstimatedAudience.campaign_price = 0;
            state.createEstimatedAudience.campaign_price_from_price_list = 0;
            state.createEstimatedAudience.discount = 0;
            state.createEstimatedAudience.error = payload;
            state.createEstimatedAudience.isPending = false;
        },

        confirmCampaign:(state,{payload})=>{
            //
        },

        itemHelper:(state,{payload})=>{
            switch (payload.type){
                case 'CALC_DAYS':{
                    const{
                        date_from,
                        date_to,
                    }=payload;
                    if(date_from && date_to){
                        const _campaignLength = campaignLengthInDays(date_from,date_to);
                        const _elapsedDays = campaignElapsedDays(date_from);

                        state.itemHelper.campaignLengthInDays = _campaignLength;

                        if( _elapsedDays > _campaignLength ){
                            state.itemHelper.elapsedDays = _campaignLength;
                        }else{
                            state.itemHelper.elapsedDays = _elapsedDays;
                        }
                    }
                  break;
                }
                default: break;
            }
        },

        shareCampaignHelper:(state,{payload})=>{
            //
        },


        fetchCampaignAudienceStats:(state,{payload})=>{
            state.campaignAudienceStats.data = {};
            state.campaignAudienceStats.isPending = true;
        },
        fetchCampaignAudienceStatsSuccess:(state,{payload})=>{
            state.campaignAudienceStats.data = {
                ...payload?.total_audience,
                daily_audience: payload?.daily_audience
            };
            state.campaignAudienceStats.isPending = false;
        },
        fetchCampaignAudienceStatsFailure:(state,{payload})=>{
            state.campaignAudienceStats.error = payload;
            state.campaignAudienceStats.isPending = false;
        }
    }
});

const campaignActions ={
    reset: createAction("campaign/reset"),
    setQuery: createAction("campaign/setQuery"),
    itemHelper: createAction("campaign/itemHelper"),

    fetchCampaigns: createAction("campaign/fetchCampaigns"),
    fetchCampaignsSuccess: createAction("campaign/fetchCampaignsSuccess"),
    fetchCampaignsFailure: createAction("campaign/fetchCampaignsFailure"),

    fetchCampaign: createAction("campaign/fetchCampaign"),
    fetchCampaignSuccess: createAction("campaign/fetchCampaignSuccess"),
    fetchCampaignFailure: createAction("campaign/fetchCampaignFailure"),

    fetchCampaignStatistics: createAction("campaign/fetchCampaignStatistics"),
    fetchCampaignStatisticsSuccess: createAction("campaign/fetchCampaignStatisticsSuccess"),
    fetchCampaignStatisticsFailure: createAction("campaign/fetchCampaignStatisticsFailure"),

    fetchCampaignEstimates: createAction("campaign/fetchCampaignEstimates"),
    fetchCampaignEstimatesSuccess: createAction("campaign/fetchCampaignEstimatesSuccess"),
    fetchCampaignEstimatesFailure: createAction("campaign/fetchCampaignEstimatesFailure"),

    createCampaign: createAction("campaign/createCampaign"),
    createCampaignSuccess: createAction("campaign/createCampaignSuccess"),
    createCampaignFailure: createAction("campaign/createCampaignFailure"),

    updateCampaign: createAction("campaign/updateCampaign"),
    updateCampaignSuccess: createAction("campaign/updateCampaignSuccess"),
    updateCampaignFailure: createAction("campaign/updateCampaignFailure"),

    fetchCampaignSpots: createAction("campaign/fetchCampaignSpots"),
    fetchCampaignSpotsSuccess: createAction("campaign/fetchCampaignSpotsSuccess"),
    fetchCampaignSpotsFailure: createAction("campaign/fetchCampaignSpotsFailure"),

    deleteCampaign: createAction("campaign/deleteCampaign"),
    deleteCampaignSuccess: createAction("campaign/deleteCampaignSuccess"),
    deleteCampaignFailure: createAction("campaign/deleteCampaignFailure"),

    uploadSpotFile: createAction("campaign/uploadSpotFile"),
    uploadSpotFileSuccess: createAction("campaign/uploadSpotFileSuccess"),
    uploadSpotFileFailure: createAction("campaign/uploadSpotFileFailure"),

    fetchCitiesWithCount: createAction("campaign/fetchCitiesWithCount"),
    fetchCitiesWithCountSuccess: createAction("campaign/fetchCitiesWithCountSuccess"),
    fetchCitiesWithCountFailure: createAction("campaign/fetchCitiesWithCountFailure"),

    fetchUsedCityScreens: createAction("campaign/fetchUsedCityScreens"),
    fetchUsedCityScreensSuccess: createAction("campaign/fetchUsedCityScreensSuccess"),
    fetchUsedCityScreensFailure: createAction("campaign/fetchUsedCityScreensFailure"),

    fetchGemiusStatistics: createAction("campaign/fetchGemiusStatistics"),
    fetchGemiusStatisticsSuccess: createAction("campaign/fetchGemiusStatisticsSuccess"),
    fetchGemiusStatisticsFailure: createAction("campaign/fetchGemiusStatisticsFailure"),

    fetchEstimatedAudience: createAction("campaign/fetchEstimatedAudience"),
    fetchEstimatedAudienceSuccess: createAction("campaign/fetchEstimatedAudienceSuccess"),
    fetchEstimatedAudienceFailure: createAction("campaign/fetchEstimatedAudienceFailure"),

    shareCampaignHelper: createAction("campaign/shareCampaignHelper"),
    shareCampaignHelperSuccess: createAction("campaign/shareCampaignHelperSuccess"),
    shareCampaignHelperFailure: createAction("campaign/shareCampaignHelperFailure"),

    confirmCampaign: createAction("campaign/confirmCampaign"),

    fetchCampaignAudienceStats: createAction("campaign/fetchCampaignAudienceStats"),
    fetchCampaignAudienceStatsSuccess: createAction("campaign/fetchCampaignAudienceStatsSuccess"),
    fetchCampaignAudienceStatsFailure: createAction("campaign/fetchCampaignAudienceStatsFailure"),
}

export {campaignActions};

export default slice.reducer;