import {call, put, takeLatest} from "redux-saga/effects";
import i18n from "i18next";
import {cToast, campaignLengthInDays} from "helpers";
import offerService from "./api";
import {offerActions} from "./data";


function* fetchOffers({payload}){
    try{
        const data = yield call(offerService.getOffers,payload);

        const reducedItems = yield data.items.reduce((acc,item)=>{
            acc.push({
                ...item,
                date_from : new Date(item.date_from),
                date_to : new Date(item.date_to),
                lengthInDays: campaignLengthInDays(item.date_from, item.date_to),
            });
            return acc;
        },[]);

        yield put(offerActions.fetchOffersSuccess({
            items: reducedItems,
            query:{
                page: data.page,
                pages: data.pages,
                size: data.size,
                total: data.total,
            },
        }));

    }catch (err) {
        yield put(offerActions.fetchOffersFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchOffer({payload}){
    try{
        const data = yield call(offerService.getOfferByUuid,payload);

        yield put(offerActions.fetchOfferSuccess({
            ...data,
            date_from : new Date(data.date_from),
            date_to : new Date(data.date_to),
            lengthInDays: campaignLengthInDays(data.date_from, data.date_to),
        }));

        yield put(offerActions.fetchOfferEstimate(payload));

    }catch (err) {
        yield put(offerActions.fetchOfferFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* fetchOfferEstimate({payload}){
    try{
        const data = yield call(offerService.getOfferEstimateByUuid,payload);
        yield put(offerActions.fetchOfferEstimateSuccess(data));
    }catch (err) {
        yield put(offerActions.fetchOfferEstimateFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

function* deleteOffer({payload}){
    const{
        uuid,
        navigate
    }=payload;
    try{
        const data = yield call(offerService.deleteOffer,uuid);
        yield put(offerActions.deleteOfferSuccess(data));
        yield navigate('/offer');
        yield cToast({msg: i18n.t("Offer was deleted"),variant:'warning'});
    }catch (err) {
        yield put(offerActions.deleteOfferFailure(err))
    }
}

function* postDataFromCsv({payload}){
    const{
        data: _data,
        navigate,
        sendType,
    }=payload;
    try{
        const data = yield call(offerService.postDataFromCsv,{_data, sendType});
        yield put(offerActions.sendDataFromCsvSuccess(data));
        if(data?.missed_screens?.length>0){
            yield cToast({msg: `${i18n.t("Some screens couldn't be imported")}: ${data?.missed_screens}`,variant:'warning'});
        }
        if(sendType === 'OFFER'){
            yield navigate('/offer');
            yield cToast({msg: i18n.t("Offer was created via csv"),variant:'success'});
        }
        if(sendType === 'CAMPAIGN'){
            yield navigate('/campaign');
            yield cToast({msg: i18n.t("Campaign was created via csv"),variant:'success'});
        }

    }catch (err) {
        yield put(offerActions.sendDataFromCsvFailure(err));
        yield cToast({msg: i18n.t("Something went wrong"),variant:'error'});
    }
}

export default function* offerSaga(){
    yield takeLatest(offerActions.fetchOffers.type, fetchOffers);
    yield takeLatest(offerActions.fetchOffer.type, fetchOffer);
    yield takeLatest(offerActions.fetchOfferEstimate.type, fetchOfferEstimate);
    yield takeLatest(offerActions.deleteOffer.type, deleteOffer);
    yield takeLatest(offerActions.sendDataFromCsv.type, postDataFromCsv);
}
