import axios from "axios";
import {API_USERS_URL} from "config";
import {arahubAPI} from "config/axios";
import Cookies from "universal-cookie";
import {mockResolve} from "helpers";

const API_ROUTE ="auth";
const cookies = new Cookies();
const auth = async (authData) =>{
    try{
        const {data} = await axios.post(`${API_USERS_URL}${API_ROUTE}`, authData,
            {
                headers:{"Content-Type":"multipart/form-data"}
            });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const logout=async()=>{
    const refreshToken = await cookies.get("refresh_token");
    try{
        const {data} = await axios.delete(`${API_USERS_URL}${API_ROUTE}/refresh-token`,{
            data:{
                refresh_token: refreshToken
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getUser = async (uuid) =>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}user/${uuid}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getUsers=async(query)=>{
    try{
        const {data} = await arahubAPI.get(`${API_USERS_URL}user`,{
            params:{
                page: query.page,
                size: query.size,
                sort_by: query?.sort_by||null,
                order: query?.order||null,
                is_active: query?.is_active||null, // => teraz wystarczy do query wpisać
                filter: query?.filter||null,
                [query.fieldFilter && Object.keys(query.fieldFilter)?.[0]] :
                query.fieldFilter && Object.values(query.fieldFilter)?.[0],
            }
        });
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}


const createUser=async(userData)=>{
    try{
        const {data} = await arahubAPI.post(`${API_USERS_URL}user`,userData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const updateUser=async({userData, id})=>{
    try{
        const {data} = await arahubAPI.put(`${API_USERS_URL}user/${id}`,userData);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const deleteUser=async(uuid)=>{
    try{
        const {data} = await arahubAPI.delete(`${API_USERS_URL}user/${uuid}`);
        return data;
    }catch(err){
        return Promise.reject(err.response);
    }
}

const getAccessToken=async(refreshToken)=>{
    try{
        const {data} = await axios.post(`${API_USERS_URL}auth/refresh-token`,{'refresh_token':refreshToken});
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
}


// me
const changePassword = async(_data)=>{
    try{
        const {data} = await arahubAPI.put(`${API_USERS_URL}me/password`,_data);
        return data;
    }catch (err) {
        return Promise.reject(err.response);
    }
    // return mockResolve(true,8000,{ok:"ok"}); /** TEST MOCK */
}


const userService = {
    auth,
    getUser,
    logout,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getAccessToken,
    changePassword,
}

export default userService;