import {all, fork} from "redux-saga/effects";
import userSaga from "redux/resources/user/sideEffects";
import campaignSaga from "redux/resources/campaign/sideEffects";
import offerSaga from "redux/resources/offer/sideEffects";
import citySaga from "redux/resources/city/sideEffects";
import roleSaga from "redux/resources/role/sideEffects";
import companySaga from "redux/resources/company/sideEffects";
import locationSaga from "redux/resources/location/sideEffects";
import deviceSaga from "redux/resources/device/sideEffects";
import industrySaga from "redux/resources/industry/sideEffects";
import yourAraboxesSaga from "../resources/youraraboxes/sideEffects";
import araboxLiveSaga from "../resources/araboxlive/sideEffects";
import gemiusSaga from "../resources/gemius/sideEffects";
import wsSaga from "../resources/_websocket/sideEffects";
import trafficSaga from "../resources/traffic/sideEffects";

const combinedSagas = [
    fork(userSaga),
    fork(campaignSaga),
    fork(offerSaga),
    fork(citySaga),
    fork(roleSaga),
    fork(companySaga),
    fork(locationSaga),
    fork(deviceSaga),
    fork(industrySaga),
    fork(yourAraboxesSaga),
    fork(araboxLiveSaga),
    fork(gemiusSaga),
    fork(wsSaga),
    fork(trafficSaga),
];

export default function* rootSaga() {
    yield all(combinedSagas);
}